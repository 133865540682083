import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'
import styled from 'styled-components'

import { Button, DocumentCard } from 'components'
import { useAppData } from 'context'
import { getDocuments, getFacility } from 'services'
import { Document } from 'types'

interface GridProps {
  view?: string
}

export const DocumentsOverview: React.FC = () => {
  const { t } = useTranslation()
  const { groupBy, sortOrder, view, dispatch } = useAppData()
  const { facilityId } = useParams()
  const [fadeOut, setFadeOut] = useState('')
  const { data: facility } = useQuery(['facility', facilityId], () => getFacility(facilityId))
  const { data: documents } = useQuery(['documents', facilityId], () => getDocuments(facilityId))

  const changeView = () => {
    setFadeOut('fadeOut')
    setTimeout(() => {
      dispatch({ type: 'setGroupBy', payload: null })
      dispatch({ type: 'setView', payload: view === 'grid' ? 'list' : 'grid' })
      setFadeOut('')
    }, documents.length * 50 + 300)
  }

  const sortDocuments = () => {
    setFadeOut('fadeOut')
    setTimeout(() => {
      dispatch({ type: 'setSortOrder', payload: sortOrder === 'asc' ? 'desc' : 'asc' })
      setFadeOut('')
    }, documents.length * 50 + 150)
  }

  const groupDocuments = (group: 'status' | 'type' | null) => {
    setFadeOut('fadeOut')
    setTimeout(() => {
      dispatch({ type: 'setGroupBy', payload: group })
      documents.sort((a: Document, b: Document) => {
        if (!group) {
          return sortOrder === 'asc'
            ? a.title.localeCompare(b.title)
            : b.title.localeCompare(a.title)
        }

        return sortOrder === 'asc'
          ? a[group].localeCompare(b[group]) || a.title.localeCompare(b.title)
          : a[group].localeCompare(b[group]) || b.title.localeCompare(a.title)
      })
      setFadeOut('')
    }, documents.length * 50 + 300)
  }

  return (
    <>
      <Toolbar>
        <span>{t('common.groupBy')}</span>
        <ToolbarBtn
          onClick={() => groupDocuments('type')}
          className={groupBy === 'type' ? 'active' : ''}
          disabled={view === 'grid'}
        >
          {t('common.type')}
        </ToolbarBtn>
        <ToolbarBtn
          onClick={() => groupDocuments('status')}
          className={groupBy === 'status' ? 'active' : ''}
          disabled={view === 'grid'}
        >
          {t('common.status')}
        </ToolbarBtn>
        <ToolbarBtn
          onClick={() => groupDocuments(null)}
          className={groupBy === null ? 'push-right active' : 'push-right'}
          disabled={view === 'grid'}
        >
          {t('common.none')}
        </ToolbarBtn>
        <Button onClick={sortDocuments} icon={sortOrder === 'asc' ? 'south' : 'north'} text='AZ' />
        <Button onClick={changeView} icon={view === 'grid' ? 'list' : 'grid'} />
        <Button link={`/docs/facilities/${facilityId}/documents/new`} icon='add' />
      </Toolbar>
      <HeadingWrapper>
        <Button icon='leftArrow' link='/docs/facilities' />
        <h1>{facility?.name}</h1>
      </HeadingWrapper>
      <DocumentsGrid view={view}>
        {!documents?.length ? (
          <p>
            {t('documents.noDocumentsYet')} &nbsp;
            <a href={`/docs/facilities/${facilityId}/documents/new`}>{t('common.addOne')}</a>
          </p>
        ) : (
          documents.map((doc, i) => (
            <React.Fragment key={i}>
              {groupBy &&
                ((i === 0 && groupBy === 'type' && documents[i - 1]?.type !== doc.type) ||
                  (groupBy === 'status' && documents[i - 1]?.status !== doc.status)) && (
                  <DocumentCard
                    className={fadeOut}
                    document={doc}
                    facilityName={facility?.name}
                    header={doc[groupBy]}
                    number={i}
                  />
                )}
              <DocumentCard
                className={`${fadeOut} ${groupBy}`}
                document={doc}
                facilityName={facility?.name}
                number={i}
                total={documents.length}
              />
            </React.Fragment>
          ))
        )}
      </DocumentsGrid>
    </>
  )
}

const Toolbar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 38px;

  > span {
    margin-right: 20px;
  }
`

const ToolbarBtn = styled.button`
  margin: 8px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.button.background};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.button.text};
  font-weight: 500;
  font-stretch: condensed;
  transition: background-color 250ms ease;

  &.active {
    background-color: ${({ theme }) => theme.colors.button.active};
    color: ${({ theme }) => theme.colors.button.textActive};
  }

  &.push-right {
    margin-right: 50px;
  }

  &:disabled {
    opacity: 0.7;

    &:hover {
      background-color: ${({ theme }) => theme.colors.button.background};
      cursor: not-allowed;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.button.backgroundHover};
    color: ${({ theme }) => theme.colors.button.textActive};
    cursor: pointer;
  }
`

const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 35px;

  > button {
    &:hover {
      background-color: transparent;
    }
  }
`

const DocumentsGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;

  ${({ view }) =>
    view === 'list' &&
    `
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  `}

  &::after {
    content: '';
    flex: auto;
  }
`
