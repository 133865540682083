import axios from 'axios'

const user = JSON.parse(localStorage.getItem('user'))

export default axios.create({
  baseURL: 'https://api.next.creamcookies.net/api',
  headers: {
    'Content-Type': 'application/json',
  },
  auth: {
    username: user?.email,
    password: user?.password,
  },
})
