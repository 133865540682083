import React from 'react'

import { Field } from 'formik'
import styled from 'styled-components'

import { cardBody, cardContainer, cardImageAvatar } from 'styles'

interface Props {
  avatar?: string
  label: string
  name: string
  image: string
  onFileChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const InputCard: React.FC<Props> = ({ avatar, image, label, name, onFileChange }) => {
  return (
    <CardContainer>
      <ImageWrapper>
        {onFileChange && <input id='file' name='file' type='file' onChange={onFileChange} />}
        <label htmlFor='file'>
          <img src={image} />
        </label>
        {avatar && <Avatar>{avatar === 'empty' ? '' : <img src={avatar} />}</Avatar>}
      </ImageWrapper>
      <CardBody>
        <InputWrapper>
          <Field type='text' className='light inline' name={name} placeholder={label} />
        </InputWrapper>
      </CardBody>
    </CardContainer>
  )
}

const CardContainer = styled.div`
  ${cardContainer}

  background-color: ${({ theme }) => theme.colors.card.backgroundAlt};

  &:hover {
    transform: scale(1);
    cursor: default;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  margin-right: 20px;
  text-align: center;
  border-radius: 4px;

  input[type='file'] {
    display: none;
  }

  label {
    width: 90px;
    height: 90px;
    cursor: pointer;
  }

  img {
    height: 90px;
    width: 90px;
    object-fit: cover;
  }
`

const Avatar = styled.div`
  ${cardImageAvatar}
`

const CardBody = styled.div`
  ${cardBody}
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  input[type='text'].inline {
    background: transparent;
    border: none;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0;
    outline: none;
    box-shadow: none;

    &::placeholder {
      color: ${({ theme }) => theme.colors.card.subtle};
    }
  }
`
