import React, { ChangeEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Info } from '@mui/icons-material'
import { Slider, Tooltip } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Formik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import { newPolicyPlaceholder } from 'assets'
import {
  FormFooter,
  FormGroup,
  FormHeader,
  FormStep,
  InputCard,
  PriorityLevelInput,
  StyledForm,
  SubmitButton,
} from 'components'
import {
  getPolicyImg,
  getPolicyTemplate,
  MainGoals,
  newPolicyImg,
  newPolicyTemplate,
  SubGoals,
  updatePolicyTemplate,
} from 'services'
import { Policy } from 'types'

interface Steps {
  [key: number]: string
}

const initialSteps: Steps = {
  1: 'fade-prev fade-in',
  2: '',
  3: '',
}

const initialValues = {
  name: '',
  goals: MainGoals,
  subgoals: SubGoals,
} as Policy

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
})

const sliderColors = ['mango', 'dawn', 'jungle']

export const NewPolicy: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { policyId } = useParams()
  const [step, setStep] = useState<keyof Steps>(1)
  const [policyImg, setPolicyImg] = useState<File>(null)
  const [previewImg, setPreviewImg] = useState(newPolicyPlaceholder)

  const { data: policy } = useQuery<Policy>(
    ['policy', policyId],
    () => getPolicyTemplate(policyId),
    {
      enabled: !!policyId,
    },
  )

  const formRef = useRef<HTMLFormElement>(null)
  const [steps, setSteps] = useState<Steps>(initialSteps)

  const goToStep = (direction: 'prev' | 'next') => {
    const currStep = step
    const stepKeys = Object.keys(steps)

    let newStep = currStep
    if (direction === 'prev' && currStep > 1) {
      newStep--
      setSteps((curr) => ({
        ...curr,
        [newStep]: 'fade-prev',
        [currStep]: 'fade-next fade-out-prev',
      }))
    } else if (direction === 'next' && step < stepKeys.length) {
      newStep++
      setSteps((curr) => ({
        ...curr,
        [newStep]: 'fade-next',
        [currStep]: 'fade-prev fade-out-next',
      }))
    }

    setStep(newStep)

    setTimeout(() => {
      if (direction === 'prev' && currStep > 1) {
        setSteps((curr) => ({ ...curr, [currStep]: '', [newStep]: 'fade-next fade-in' }))
      } else if (direction === 'next' && step < stepKeys.length) {
        setSteps((curr) => ({ ...curr, [currStep]: '', [newStep]: 'fade-prev fade-in' }))
      }
    }, 150)
  }

  useQuery(['policyImage', policy?.id], () => getPolicyImg(policy?.id), {
    enabled: !!policy?.image && !policyImg,
    onSuccess(data) {
      if (!policyImg) setPreviewImg(data)
    },
  })

  const handleImgChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    if (!target.files || target.files.length === 0) return

    setPolicyImg(target.files[0])
    setPreviewImg(URL.createObjectURL(target.files[0]))
  }

  const newPolicyMutation = useMutation((values: Policy) => newPolicyTemplate(values))
  const updatePolicyMutation = useMutation((values: Policy) =>
    updatePolicyTemplate(policy.id, values),
  )
  const newPolicyImageMutation = useMutation((policyId: string) =>
    newPolicyImg(policyId, policyImg),
  )

  const policyMutation = policy ? updatePolicyMutation : newPolicyMutation

  const handleSubmit = (values: Policy) => {
    const policy = JSON.parse(JSON.stringify(values))
    policyMutation.mutate(policy, {
      onSuccess: (policyId) => {
        if (policyImg) {
          newPolicyImageMutation.mutate(policyId, {
            onSuccess: () => {
              navigate('/map/policies')
            },
          })
        } else {
          navigate('/map/policies')
        }
      },
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount={true}
    >
      {({ values, isValid, handleChange, setFieldValue, submitForm }) => {
        const handleSliderChange = (event: Event, value: number) => {
          const target = event.target as HTMLInputElement
          const split = target.name.split('.')

          if (split.length === 3) {
            setFieldValue(`${split[0]}.${split[2]}.${split[1]}`, 9 - value)
          } else if (split.length === 4) {
            setFieldValue(`${split[0]}.${split[1]}.${split[3]}.${split[2]}`, 9 - value)
          }

          handleChange(event)
        }

        return (
          <StyledForm ref={formRef} style={{ position: 'relative' }}>
            <FormHeader>
              <h1>{t('policies.newTemplate')}</h1>
            </FormHeader>
            <FormStep className={`${steps[1]}`}>
              <FormGroup width='narrow'>
                <h3>1. {t('policies.appearance')}</h3>
                <InputCard
                  image={previewImg}
                  label={t('policies.addName')}
                  name='name'
                  onFileChange={handleImgChange}
                />
              </FormGroup>

              <FormGroup>
                <ToolTipWrapper>
                  <h3>2. {t('policies.priorityLevel')}</h3>
                  <Tooltip title={t('policies.tooltips.priorityLevel')} placement='top'>
                    <Info fontSize='inherit' />
                  </Tooltip>
                </ToolTipWrapper>

                <PriorityLevelInput handleChange={(level) => setFieldValue('priority', level)} />
              </FormGroup>
            </FormStep>

            <FormStep className={`${steps[2]}`}>
              <FormGroup width='wide'>
                <ToolTipWrapper>
                  <h3>3. {t('policies.mainGoals')}</h3>
                  <Tooltip title={t('policies.tooltips.mainGoals')} placement='top'>
                    <Info fontSize='inherit' />
                  </Tooltip>
                </ToolTipWrapper>
                <p>{t('policies.mainGoalsDescription')}</p>
              </FormGroup>

              <FormGroup>
                {Object.keys(MainGoals).map((goal, i) => (
                  <SliderGroup key={i}>
                    <span>{t(`policies.goals.${goal}`)}</span>
                    <Sliders>
                      {Object.keys(MainGoals[goal as keyof typeof MainGoals]).map((subgoal, j) => {
                        const mainGoal = goal as keyof typeof MainGoals
                        const mainGoalValue = MainGoals[mainGoal]
                        const subGoal = subgoal as keyof typeof mainGoalValue

                        return (
                          <MaingoalSlider key={j}>
                            <Slider
                              value={values.goals[mainGoal][subGoal]}
                              valueLabelDisplay='auto'
                              name={`goals.${goal}.${subgoal}`}
                              step={1}
                              min={0}
                              max={9}
                              className={
                                values.goals[mainGoal][subGoal] !== 4.5
                                  ? `touched goal-${mainGoal} subgoal-${subGoal}`
                                  : `goal-${mainGoal} subgoal-${subGoal}`
                              }
                              classes={{
                                thumb: `${sliderColors[0]}`,
                                track: `${sliderColors[0]}`,
                                rail: `${sliderColors[1]}`,
                              }}
                              onChange={handleSliderChange}
                            />
                            <span>{t(`policies.goals.${subgoal}`)}</span>
                          </MaingoalSlider>
                        )
                      })}
                    </Sliders>
                  </SliderGroup>
                ))}
              </FormGroup>
            </FormStep>

            <FormStep className={`${steps[3]}`}>
              <FormGroup>
                <ToolTipWrapper>
                  <h3>4. {t('policies.concerns')}</h3>
                  <Tooltip title={t('policies.tooltips.concerns')} placement='top'>
                    <Info fontSize='inherit' />
                  </Tooltip>
                </ToolTipWrapper>
                <p>{t('policies.concernsDescription')}</p>
              </FormGroup>

              <FormGroup>
                {Object.keys(SubGoals).map((goal, i) => {
                  const mainGoal = goal as keyof typeof MainGoals
                  const mainGoalValue = MainGoals[mainGoal]
                  const subgoals = Object.keys(SubGoals[goal as keyof typeof SubGoals])

                  return (
                    <SliderGroup key={i}>
                      <span>{t(`policies.goals.${mainGoal}`)}</span>
                      <Sliders className='active'>
                        {subgoals.map((subgoal, j) => {
                          const isLast = j === subgoals.length - 1
                          const sliders = subgoals.slice(j + 1)

                          if (isLast) return null

                          return sliders.map((slider, k) => {
                            const subGoal = subgoal as keyof typeof mainGoalValue
                            const sliderGoal = slider as keyof typeof mainGoalValue

                            return (
                              <SubgoalSlider key={k}>
                                <SliderLabels>
                                  <span>{t(`policies.subgoals.${subgoal}`)}</span>
                                  <span>{t(`policies.subgoals.${slider}`)}</span>
                                </SliderLabels>
                                <Slider
                                  value={values.subgoals[mainGoal][subGoal][sliderGoal]}
                                  valueLabelDisplay='off'
                                  name={`subgoals.${goal}.${subgoal}.${slider}`}
                                  step={1}
                                  min={0}
                                  max={9}
                                  className={
                                    `goal-${mainGoal} subgoal-${subGoal} slidergoal-${sliderGoal}` &&
                                    values.subgoals[mainGoal][subGoal][sliderGoal] !== 4.5
                                      ? 'touched'
                                      : ''
                                  }
                                  classes={{
                                    thumb: `${sliderColors[j]}`,
                                    track: `${sliderColors[j]}`,
                                    rail: `${sliderColors[j + k + 1]}`,
                                  }}
                                  onChange={handleSliderChange}
                                />
                              </SubgoalSlider>
                            )
                          })
                        })}
                      </Sliders>
                    </SliderGroup>
                  )
                })}
              </FormGroup>
            </FormStep>
            <FormFooter>
              <div>
                <SubmitButton
                  type='secondary'
                  submit={false}
                  submitText='Previous'
                  disabled={step === 1}
                  onClick={() => goToStep('prev')}
                />
                <SubmitButton
                  type='primary'
                  submit={false}
                  submitText={step === 3 ? 'Save' : 'Next'}
                  disabled={step === 3 && !isValid ? true : false}
                  onClick={() => (step === 3 ? submitForm() : goToStep('next'))}
                />
              </div>
            </FormFooter>
          </StyledForm>
        )
      }}
    </Formik>
  )
}

const ToolTipWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`

const SliderGroup = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 45px;
  font-stretch: condensed;

  > span {
    width: 145px;
  }
`

const Sliders = styled.div`
  display: flex;
  position: relative;
  flex-flow: row wrap;
  top: 0;
  left: 0;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -25px;
    width: 1px;
    height: calc(100% - 20px);
    background: ${({ theme }) => theme.colors.body.backgroundSubtle};
  }

  .MuiSlider-root {
    width: 300px;
    margin: 0 25px 5px 0;

    .MuiSlider-track {
      background-color: ${({ theme }) => theme.colors.inputs.slider.fill};
      border: none;
    }

    .MuiSlider-rail {
      background-color: ${({ theme }) => theme.colors.inputs.slider.fill};
      opacity: 1;
    }

    .MuiSlider-thumb {
      width: 10px;
      height: 20px;
      background-color: ${({ theme }) => theme.colors.inputs.slider.thumb};
      border-radius: 2px;
    }

    &.touched {
      .mango {
        background-color: ${({ theme }) =>
          theme.mode === 'dark' ? theme.colors.mango.hex : theme.colors.mangoLight.hex};
      }

      .dawn {
        background-color: ${({ theme }) =>
          theme.mode === 'dark' ? theme.colors.dawn.hex : theme.colors.dawnLight.hex};
      }

      .jungle {
        background-color: ${({ theme }) =>
          theme.mode === 'dark' ? theme.colors.jungle.hex : theme.colors.jungleLight.hex};
      }
    }
  }
`
const MaingoalSlider = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
`
const SubgoalSlider = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`
const SliderLabels = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  width: 300px;

  > span {
    width: 145px;

    &:last-child {
      text-align: right;
    }
  }
`
