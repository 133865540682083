import { User } from 'types'

import http from './http-common'

export const authenticateUser = async (user: User) => {
  try {
    const res = await http.get('/home/ping', {
      auth: {
        username: user.email,
        password: user.password,
      },
    })

    return res.data
  } catch (err) {
    console.debug(err)
  }
}
