import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import styled from 'styled-components'

import { Button, DropdownItem, DropdownMenu } from 'components'
import { ConfirmationModal } from 'components/ConfirmationModal'
import { useOutsideClick } from 'hooks'
import { deleteRequest, getFacility, putRequest } from 'services'
import { cardContainer, cardDetailItem, cardDetailList, cardHeader } from 'styles'
import { Request } from 'types'

interface Props {
  request: Request
  number?: number
  total?: number
}

export const RequestCard: React.FC<Props> = ({ number, request, total }) => {
  const [confirmModalState, setConfirmModalState] = useState<'open' | 'closed'>('closed')
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [showDropdown, setShowDropdown] = useState(false)
  const date = new Date(request.createdUtc)
  const contactIds = request.contacts?.flatMap((contact) => contact.id) ?? []

  const { data: facility } = useQuery(['facility', request.facilityId], () =>
    getFacility(request.facilityId),
  )

  const handleOutsideClick = () => {
    setShowDropdown(false)
  }

  const dropdownRef = useOutsideClick(handleOutsideClick)

  const changeStatus = useMutation(
    (status: string) =>
      putRequest(request.facilityId, request.id, {
        ...request,
        contactIds,
        status,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['requests', request.facilityId] })
      },
    },
  )

  const removeRequestMutation = useMutation(() => deleteRequest(request.facilityId, request.id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['requests', request.facilityId] })
    },
  })

  return (
    <>
      <CardContainer number={number} total={total}>
        <CardHeader>
          <h3
            onClick={() => navigate(`/fix/facilities/${request.facilityId}/requests/${request.id}`)}
          >
            {request.type}
          </h3>
          <DropdownMenu innerRef={dropdownRef} show={showDropdown} setShow={setShowDropdown}>
            <DropdownItem
              name='edit'
              action={() =>
                navigate(`/fix/facilities/${request.facilityId}/requests/${request.id}/edit`)
              }
            />
            <DropdownItem
              name='delete'
              style='danger'
              action={() => removeRequestMutation.mutate()}
            />
          </DropdownMenu>
        </CardHeader>
        <TextWrapper
          onClick={() => navigate(`/fix/facilities/${request.facilityId}/requests/${request.id}`)}
        >
          <DetailList>
            <DetailItem>{facility?.name}</DetailItem>
            <DetailItem>{date.toLocaleString('en', { day: '2-digit', month: 'short' })}</DetailItem>
          </DetailList>
          <Text>{request.details}</Text>
        </TextWrapper>
        {request.status !== 'Resolved' ? (
          <Button onClick={() => setConfirmModalState('open')} text='Mark as resolved' />
        ) : (
          <Button onClick={() => setConfirmModalState('open')} text='Mark as unresolved' />
        )}
      </CardContainer>
      <ConfirmationModal
        title={request.status === 'None' ? 'Was this issue addressed?' : 'Unresolve this issue?'}
        message={`You’re about to ${request.status === 'None' ? 'resolve' : 'unresolve'} a ${
          request?.type
        } in ${facility?.name} reported on ${date?.toLocaleString('en', {
          day: '2-digit',
          month: 'short',
        })} by John Doe. The reporter and the service provider will be notified by email.`}
        confirmModalState={confirmModalState}
        onCancel={() => setConfirmModalState('closed')}
        onConfirm={() => {
          request?.status === 'None' ? changeStatus.mutate('Resolved') : changeStatus.mutate('None')
        }}
        confirmText={request?.status === 'None' ? 'Yes, it’s resolved' : 'Yes, unresolve'}
        cancelText={
          request?.status === 'None' ? 'No, still needs attention' : 'No, it’s still resolved'
        }
      />
    </>
  )
}

const CardContainer = styled.div`
  ${cardContainer}

  flex-flow: row wrap;
  padding: 12px 20px;

  &:hover {
    cursor: default;
  }

  button {
    width: 100%;
  }
`

const CardHeader = styled.div`
  ${cardHeader}

  h3:hover {
    cursor: pointer;
  }
`

const DetailList = styled.ul`
  ${cardDetailList}
`

const DetailItem = styled.li`
  ${cardDetailItem}

  font-weight: 500;
  font-stretch: condensed;
`

const Text = styled.p`
  display: -webkit-box;
  height: 40px;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const TextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`
