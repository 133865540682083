import React from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'
import styled from 'styled-components'

import { Button, ClusterCard } from 'components'
import { getClusters, getFacility, getFacilityImg } from 'services'

interface Props {
  facilityId?: string
  isActive?: string
}

export const FacilityClustersTab: React.FC<Props> = ({ facilityId }) => {
  const { t } = useTranslation()
  const { data: facility } = useQuery(['facility'], () => getFacility(facilityId))
  const { data: clusters } = useQuery(['clusters', facilityId], () => getClusters(facilityId))

  const { data: image } = useQuery(
    ['facilityImage', facilityId],
    () => getFacilityImg(facilityId),
    {
      enabled: !!facility?.img,
    },
  )

  return (
    <>
      <Header>
        <h3>{t('clusters.clusters')}</h3>
        <Button link={`/map/facilities/${facilityId}/clusters/new`} icon='add' />
      </Header>

      {!clusters?.length ? (
        <p>
          {t('clusters.noClusters')} &nbsp;
          <a href={`/map/facilities/${facilityId}/clusters/new`}>{t('common.create')}</a>
        </p>
      ) : (
        <ClusterWrapper>
          {clusters.map((cluster, i) => (
            <ClusterCard key={cluster.id} cluster={cluster} image={image} number={i} />
          ))}
        </ClusterWrapper>
      )}
    </>
  )
}

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const ClusterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
`
