import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { useMutation, useQuery } from '@tanstack/react-query'
import i18n from 'i18n'
import styled from 'styled-components'

import { Button, DropdownItem, DropdownMenu, TabBar, TabView, Toolbar, Topbar } from 'components'
import { useOutsideClick } from 'hooks'
import { deletePolicyTemplate, getPolicyImg, getPolicyTemplate, PriorityLevels } from 'services'
import { PolicyClustersTab, PolicyDetailsTab } from 'views'

interface Tab {
  label: string
  component: React.ReactElement
}

export const PolicyDetail: React.FC = () => {
  const { t } = useTranslation()
  const { policyId } = useParams()
  const navigate = useNavigate()
  const [showDropdown, setShowDropdown] = React.useState(false)
  const ref = useOutsideClick(() => setShowDropdown(false))

  const Tabs: Tab[] = [
    {
      label: i18n.t('facilities.details.details'),
      component: <PolicyDetailsTab policyId={policyId} />,
    },
    { label: i18n.t('clusters.clusters'), component: <PolicyClustersTab policyId={policyId} /> },
  ]

  const { data: policyTemplate } = useQuery(['policyTemplate', policyId], () =>
    getPolicyTemplate(policyId),
  )

  const { data: policyImage } = useQuery(['policyImg', policyId], () => getPolicyImg(policyId), {
    enabled: !!policyTemplate && !!policyTemplate.image,
    staleTime: 1000 * 60 * 60,
  })

  const removePolicyTemplate = useMutation((id: string) => deletePolicyTemplate(id), {
    onSuccess: () => {
      navigate('/map/policies')
    },
  })

  const editPolicyTemplate = () => {
    navigate(`/map/policies/${policyId}/edit`)
  }

  const priorityLevel = PriorityLevels.find((level) => level.value === policyTemplate?.priority)

  return (
    <>
      <Topbar>
        {!!policyImage && <ImageWrapper>{policyImage && <img src={policyImage} />}</ImageWrapper>}
        <Information>
          <h1>{policyTemplate?.name}</h1>
          <Category>Policy template</Category>

          {priorityLevel && (
            <Specifications>
              <Spec>
                <Value style={{ color: '#6dc5ab' }}>{policyTemplate.priority.substring(1)}</Value>
                <Label>priority</Label>
              </Spec>
              <Spec>
                <Value>{priorityLevel.details.timeToResponse}</Value>
                <Label>{t('policies.priority.details.timeToResponse')}</Label>
              </Spec>
              <Spec>
                <Value>{priorityLevel.details.timeToResolve}</Value>
                <Label>{t('policies.priority.details.timeToResolve')}</Label>
              </Spec>
              <Spec>
                <Value>{priorityLevel.details.mandate}</Value>
                <Label>{t('policies.priority.details.mandate')}</Label>
              </Spec>
              <Spec>
                {/*  Todo: add translate and use the correct value (clusters by policyId) */}
                <Value>{(Math.random() * (10 - 7) + 1).toFixed(0)}</Value>
                <Label>clusters</Label>
              </Spec>
            </Specifications>
          )}
        </Information>
        <Toolbar>
          <Button link={'/map/policies/new'} icon='add' />
          <DropdownMenu innerRef={ref} show={showDropdown} setShow={setShowDropdown}>
            <DropdownItem name={t('common.edit')} action={editPolicyTemplate} />
            <DropdownItem
              name={t('common.delete')}
              style='danger'
              action={() => removePolicyTemplate.mutate(policyId)}
            />
          </DropdownMenu>
        </Toolbar>
      </Topbar>
      <TabBar tabs={Tabs.flatMap((tab) => tab.label)}>
        {Tabs.map((tab) => (
          <TabView key={tab.label} tab={tab.label}>
            {tab.component}
          </TabView>
        ))}
      </TabBar>
    </>
  )
}

const Information = styled.div``

const Category = styled.p`
  margin: 10px 0 0 0;
`

const Specifications = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 40px;
`

const Spec = styled.div`
  margin-right: 40px;
`

const Value = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 300;
  font-stretch: condensed;
`

const Label = styled.span`
  display: block;
  font-size: 12px;
`

const ImageWrapper = styled.div`
  position: relative;
  width: 180px;
  height: 180px;
  margin-right: 40px;
  backdrop-filter: blur(3px);

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;

    object-fit: cover;
  }
`
