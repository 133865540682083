import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Alert, Snackbar } from '@mui/material'
import { Formik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as UnicaLogo } from 'assets/unica-logo.svg'
import { AnimatedInput, FormGroup, StyledForm, SubmitButton } from 'components'
import { useAppData } from 'context'
import { authenticateUser } from 'services'
import { User } from 'types'

const initialValues = {
  email: '',
  password: '',
  authorized: false,
} as User

const loginInfoSchema = Yup.object().shape({
  email: Yup.string().required('Email is a required field').email('Invalid email format'),
  password: Yup.string()
    .required('Password is a required field')
    .min(8, 'Password must be at least 8 characters'),
})

export const Login: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { dispatch } = useAppData()
  const [error, setError] = React.useState(false)

  const handleClose = () => {
    setError(false)
  }

  const handleSubmit = async (values: User) => {
    const res = await authenticateUser(values)

    if (res) {
      dispatch({ payload: values, type: 'setUser' })
      const currentRoute = window.location.pathname

      navigate(currentRoute)
      navigate(0)
    } else {
      setError(true)
    }
  }

  return (
    <>
      <StyledUnicaLogo alt='Unica Logo' />
      <Container>
        <LoginWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={loginInfoSchema}
            onSubmit={handleSubmit}
            validateOnMount={true}
          >
            {({ values, isSubmitting, isValid, handleChange, errors, touched, setFieldValue }) => {
              return (
                <StyledForm>
                  <h1>Login</h1>
                  <FormGroup width='narrow'>
                    <h3>Email</h3>
                    <AnimatedInput
                      label='email'
                      name='email'
                      error={errors.email && touched.email ? errors.email : ''}
                      handleChange={handleChange}
                    />
                    <h3>Password</h3>
                    <AnimatedInput
                      label='password'
                      name='password'
                      type='password'
                      error={errors.password && touched.password ? errors.password : ''}
                      handleChange={handleChange}
                    />
                  </FormGroup>
                  <SubmitButton
                    completed={false}
                    completedText={t('login.succesfull')}
                    submitText={'Login'}
                    loading={isSubmitting}
                    disabled={!isValid}
                    type='login'
                    submit={true}
                  />
                </StyledForm>
              )
            }}
          </Formik>
        </LoginWrapper>
        {error && (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={error}
            onClose={handleClose}
            autoHideDuration={3000}
          >
            <Alert severity='error'>{t('login.error')}</Alert>
          </Snackbar>
        )}
      </Container>
    </>
  )
}

const StyledUnicaLogo = styled(UnicaLogo)`
  width: 200px;
  height: auto;
  margin: 30px auto;

  path {
    fill: ${({ theme }) => theme.colors.body.icon};
  }
`
const Container = styled.div`
  display: flex;
  max-width: 1440px;
  justify-content: center;
  margin-top: 50px;
`

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.loginWrapper.background};
  color: ${({ theme }) => theme.colors.loginWrapper.text};
  border-radius: 5px;
  padding: 30px 25px;
  row-gap: 25px;
`
