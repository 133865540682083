import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

interface Props {
  accept: string
  draggable?: boolean
  multiple?: boolean
  name: string
  setExtraValue?: string
  setFieldValue: (field: string, value: File | File[] | string) => void
}

export const StyledFileInput: React.FC<Props> = ({
  accept,
  draggable = false,
  multiple = false,
  name,
  setExtraValue,
  setFieldValue,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const [dragActive, setDragActive] = useState(false)
  const [files, setFiles] = useState<File[]>([])

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(e.type === 'dragenter' || e.type === 'dragover')
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFiles([...e.dataTransfer.files])
    }
  }

  const deleteFile = (i: number) => {
    const list = new DataTransfer()
    const array = [...files]
    array.splice(i, 1)
    array.forEach((file) => list.items.add(file))
    inputRef.current.files = list.files

    setFiles(array)
  }

  const parseFileName = (fileName: string) => {
    const re = /(?:\.([^.]+))?$/
    const ext = re.exec(fileName)[1]
    const name = fileName?.replace(ext, '') || ''

    return { ext, name }
  }

  useEffect(() => {
    multiple ? setFieldValue('files', [...files]) : setFieldValue('file', files[0] || null)

    if (setExtraValue) {
      const { name } = parseFileName(files[0]?.name)
      setFieldValue(setExtraValue, name.slice(0, 32))
    }
  }, [files])

  return (
    <>
      <Wrapper className={draggable ? 'draggable' : ''} htmlFor='file' onDragEnter={handleDrag}>
        {draggable ? (
          <>
            {t('documents.dropFile')}
            <UploadButton type='button' onClick={() => inputRef.current.click()}>
              {t('documents.browseFile')}
            </UploadButton>
          </>
        ) : (
          <UploadButton type='button' onClick={() => inputRef.current.click()}>
            {t('documents.addFiles')}
          </UploadButton>
        )}
        <input
          id='file'
          name={name}
          type='file'
          accept={accept}
          ref={inputRef}
          onChange={() => setFiles([...inputRef.current.files])}
          multiple={multiple}
        />
        {draggable && (
          <DragMask
            className={dragActive ? 'active' : ''}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </Wrapper>
      <FilePreviewWrapper>
        {files?.length > 0 &&
          [...files].map((file, i) => {
            const { name, ext } = parseFileName(file.name)

            let preview = null
            if (file.type.split('/').includes('image')) {
              preview = URL.createObjectURL(file)
            }

            return (
              <FilePreview key={i}>
                {preview && (
                  <>
                    <img src={preview} alt={name} />
                    <span className='mask' />
                  </>
                )}
                <span className='remove' onClick={() => deleteFile(i)} />
                <span className='name'>{name}</span>
                <span className='ext'>.{ext}</span>
              </FilePreview>
            )
          })}
      </FilePreviewWrapper>
    </>
  )
}

const FilePreviewWrapper = styled.div`
  display: flex;
  flex-wrap: row wrap;
`

const FilePreview = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  width: 90px;
  height: 90px;
  margin: 15px 15px -15px 0;
  padding: 6px;
  background-color: ${({ theme }) => theme.colors.filePreview.background};
  color: ${({ theme }) => theme.colors.filePreview.text};
  border-radius: 4px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    z-index: 5;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.filePreview.background};
    border-radius: 4px;
    opacity: 0.5;
    z-index: 7;
  }

  .remove {
    display: block;
    position: absolute;
    top: 6px;
    right: 6px;
    width: 18px;
    height: 18px;
    background-color: ${({ theme }) => theme.colors.filePreview.button};
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;

    &::after {
      content: '\u00d7';
      position: absolute;
      top: -1.75px;
      left: 3.75px;
    }
  }

  .name {
    display: -webkit-box;
    margin-bottom: 4px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    z-index: 10;
  }

  .ext {
    font-weight: 500;
    font-stretch: condensed;
    text-transform: uppercase;
    z-index: 10;
  }
`
const Wrapper = styled.label`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding: 15px 0;

  &.draggable {
    row-gap: 10px;
    align-items: center;
    padding: 44px 0;
    background-color: ${({ theme }) => theme.colors.card.backgroundAlt};
    border: solid 1px ${({ theme }) => theme.colors.body.text};
  }

  input[type='file'] {
    display: none;
  }
`
const UploadButton = styled.button`
  display: flex;
  padding: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.body.primaryLight};
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }

  ${Wrapper}.draggable & {
    padding: 8px 16px;
    background-color: ${({ theme }) => theme.colors.body.primary};
    color: ${({ theme }) => theme.colors.button.text};
    font-weight: 300;
    border-radius: 4px;
    transition: background-color 250ms ease;

    &:hover {
      background-color: ${({ theme }) => theme.colors.body.primaryLight};
    }
  }
`
const DragMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.body.primary};
  opacity: 0;
  transition: opacity 250ms ease, z-index 0ms ease 0ms;
  z-index: -1;

  &.active {
    opacity: 0.2;
    transition: z-index 0ms ease 250ms;
    z-index: 10;
  }
`
