import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { AppDataProvider } from 'context'
import { Router } from 'routing'
import { Theme } from 'styles'

import './i18n'

const container = document.getElementById('root')
const root = createRoot(container || null)
const queryClient = new QueryClient()

const AppWrapper: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppDataProvider>
        <StrictMode>
          <Theme>
            <Router />
          </Theme>
        </StrictMode>
      </AppDataProvider>
    </QueryClientProvider>
  )
}

root.render(<AppWrapper />)
