import { Request } from 'types'

import http from './http-common'

export const getRequests = (facilityId: string): Promise<Request[]> =>
  http
    .get(`/facility/${facilityId}/request`, { params: { facilityId: facilityId } })
    .then((response) => response.data)

export const newRequest = (facilityId: string, request: Request) =>
  http.post(`/facility/${facilityId}/request`, request).then((response) => response.data)

export const deleteRequest = (facilityId: string, requestId: string) =>
  http.delete(`/facility/${facilityId}/request/${requestId}`).then((response) => response.data)

export const putRequest = (facilityId: string, requestId: string, data: Request) =>
  http.put(`/facility/${facilityId}/request/${requestId}`, data).then((response) => response.data)

export const getRequestFile = async (facilityId: string, requestId: string, fileId: string) =>
  await http.get<Blob>(`/facility/${facilityId}/request/${requestId}/download/${fileId}`, {
    responseType: 'blob',
  })

export const putRequestFile = async (facilityId: string, requestId: string, file: File) => {
  try {
    const id = await http.put(
      `/facility/${facilityId}/request/${requestId}/upload`,
      {
        file: file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    return id
  } catch (e) {
    console.debug(e)
  }
}
