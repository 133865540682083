import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import { ReactComponent as UnicaLogo } from 'assets/unica-logo.svg'
import { Button, Navbar } from 'components'

interface Props {
  toolIcon: React.ReactElement
  title: string
  links: Link[] | []
}

type Link = {
  path: string
  label: string
}

export const Header: React.FC<Props> = ({ toolIcon, title, links }) => {
  const navigate = useNavigate()

  return (
    <HeaderWrapper>
      <Container>
        <PrimaryNav>
          {window.location.pathname !== '/' ? (
            <Button icon='leftArrow' link='/' />
          ) : (
            <StyledUnicaLogo alt='Unica Logo' />
          )}
          <HomeButtonWrapper onClick={() => navigate('facilities')}>
            {toolIcon}
            <span>{title}</span>
          </HomeButtonWrapper>
        </PrimaryNav>
        <Navbar links={links} />
      </Container>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.header.background};
  z-index: 500;
`

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: 1440px;
  margin: 0 auto;
`

const PrimaryNav = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: flex-start;

  button {
    position: relative;
    margin: 0 15px 0 5px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 2px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.body.background};
    }
  }
`

const HomeButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-grow: 1;
  gap: 10px;
  cursor: pointer;

  svg {
    width: auto;
    height: 30px;

    path {
      fill: ${({ theme }) => theme.colors.body.icon};
    }
  }
`

const StyledUnicaLogo = styled(UnicaLogo)`
  display: flex;
  width: 70px;
  margin: 15px 90px;

  path {
    fill: ${({ theme }) => theme.colors.body.icon};
  }
`
