import React from 'react'
import { useTranslation } from 'react-i18next'

import styled, { useTheme } from 'styled-components'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryLine,
  VictoryStack,
  VictoryTheme,
} from 'victory'

import { Button, SubmitButton, ValuesBox } from 'components'

interface Props {
  facilityId: string
}

const formatter = new Intl.NumberFormat('en-US', { notation: 'compact' })

// dummy data
const gasData = [
  { quarter: 1, costs: 9900 },
  { quarter: 2, costs: 8800 },
  { quarter: 3, costs: 9500 },
  { quarter: 4, costs: 7500 },
]

const maintenanceData = [
  { quarter: 1, costs: 5000 },
  { quarter: 2, costs: 4000 },
  { quarter: 3, costs: 5200 },
  { quarter: 4, costs: 3500 },
]

const exploitationData = [
  { quarter: 1, costs: 2100 },
  { quarter: 2, costs: 1900 },
  { quarter: 3, costs: 2000 },
  { quarter: 4, costs: 1700 },
]

const electricityData = [
  { quarter: 1, costs: 2400 },
  { quarter: 2, costs: 2100 },
  { quarter: 3, costs: 2250 },
  { quarter: 4, costs: 1850 },
]

const marketAvgData = [
  { quarter: 1, costs: 16000 },
  { quarter: 2, costs: 11000 },
  { quarter: 3, costs: 13000 },
  { quarter: 4, costs: 16000 },
]

const currContractData = [
  { quarter: 1, costs: 13000 },
  { quarter: 2, costs: 9500 },
  { quarter: 3, costs: 10500 },
  { quarter: 4, costs: 13000 },
]

const newContractData = [
  { quarter: 1, costs: 8000 },
  { quarter: 2, costs: 12000 },
  { quarter: 3, costs: 9500 },
  { quarter: 4, costs: 11000 },
]

export const FacilityCostsTab: React.FC<Props> = ({ facilityId }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const costsDummyData = [
    { label: 'Gas', value: 9900, fill: theme.colors.dawn.hex },
    { label: 'Maintenance', value: 4800, fill: theme.colors.turqoise.hex },
    { label: 'Exploitation', value: 2100, fill: theme.colors.jungle.hex },
    { label: 'Electricity', value: 2400, fill: theme.colors.mango.hex },
  ]

  const today = new Date()
  const year = today.getFullYear().toString().substr(-2)
  const quarter = Math.floor((today.getMonth() + 3) / 3)
  const prevQuarter = quarter === 1 ? 4 : quarter - 1
  const totalCosts = costsDummyData.reduce((prev, curr) => prev + curr.value, 0)

  return (
    <Container>
      <Header>
        <h3>{t('facilities.details.costs')}</h3>
        <Button link={`/map/facilities/${facilityId}/costs/new`} icon='add' />
      </Header>

      <Sidebar>
        <ValuesBox values={costsDummyData} />
        <DetailsWrapper>
          <Detail>
            <span>{`Q${quarter}'${year}`}</span>
            <span>{t('costs.period')}</span>
          </Detail>
          <Detail>
            <span className='currency'>{formatter.format(totalCosts)}</span>
            <span>{t('costs.totalCost')}</span>
          </Detail>
          <Detail>
            <span>+X%</span>
            <span>{`${t('costs.comparingTo')} Q${prevQuarter}`}</span>
          </Detail>
        </DetailsWrapper>
        <SubmitButton
          block={true}
          submit={false}
          submitText={t('costs.requestAdvice')}
          type='secondary'
        />
      </Sidebar>

      <ChartWrapper>
        <p>{t('costs.costsRealEstate')}</p>
        <VictoryChart
          domainPadding={20}
          padding={{ top: 0, right: 20, left: -20, bottom: 55 }}
          theme={VictoryTheme.material}
        >
          <VictoryAxis
            tickValues={[1, 2, 3, 4]}
            tickFormat={['Q1', 'Q2', 'Q3', 'Q4']}
            style={{
              tickLabels: { fill: theme.colors.body.text },
              grid: { stroke: theme.colors.body.background, strokeDasharray: 0 },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickCount={10}
            tickFormat={(x) => `${x / 1000}k`}
            style={{
              tickLabels: { fill: theme.colors.body.text },
              grid: { stroke: theme.colors.body.background, strokeDasharray: 0 },
            }}
          />
          <VictoryStack>
            <VictoryBar
              data={gasData}
              x='quarter'
              y='costs'
              style={{ data: { fill: theme.colors.dawn.hex } }}
            />
            <VictoryBar
              data={maintenanceData}
              x='quarter'
              y='costs'
              style={{ data: { fill: theme.colors.turqoise.hex } }}
            />
            <VictoryBar
              data={exploitationData}
              x='quarter'
              y='costs'
              style={{ data: { fill: theme.colors.jungle.hex } }}
            />
            <VictoryBar
              data={electricityData}
              x='quarter'
              y='costs'
              style={{ data: { fill: theme.colors.mango.hex } }}
            />
          </VictoryStack>
          <VictoryLegend
            x={0}
            y={325}
            orientation='horizontal'
            gutter={20}
            style={{ labels: { fill: theme.colors.body.text } }}
            data={[
              { name: 'Gas', symbol: { fill: theme.colors.dawn.hex } },
              { name: 'Maintenance', symbol: { fill: theme.colors.turqoise.hex } },
              { name: 'Exploitation', symbol: { fill: theme.colors.jungle.hex } },
              { name: 'Electricity', symbol: { fill: theme.colors.mango.hex } },
            ]}
          />
        </VictoryChart>
      </ChartWrapper>

      <ChartWrapper>
        <p>{t('costs.contracts')}</p>
        <VictoryChart
          domainPadding={20}
          padding={{ top: 0, right: 20, left: -20, bottom: 55 }}
          theme={VictoryTheme.material}
        >
          <VictoryAxis
            tickValues={[1, 2, 3, 4]}
            tickFormat={['Q1', 'Q2', 'Q3', 'Q4']}
            style={{
              tickLabels: { fill: theme.colors.body.text },
              grid: { stroke: theme.colors.body.background, strokeDasharray: 0 },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickCount={10}
            tickFormat={(x) => `${x / 1000}k`}
            style={{
              tickLabels: { fill: theme.colors.body.text },
              grid: { stroke: theme.colors.body.background, strokeDasharray: 0 },
            }}
          />
          <VictoryLine
            data={marketAvgData}
            x='quarter'
            y='costs'
            style={{ data: { stroke: theme.colors.dawn.hex } }}
          />
          <VictoryLine
            data={currContractData}
            x='quarter'
            y='costs'
            style={{ data: { stroke: theme.colors.mango.hex } }}
          />
          <VictoryLine
            data={newContractData}
            x='quarter'
            y='costs'
            style={{ data: { stroke: theme.colors.jungle.hex } }}
          />
          <VictoryLegend
            x={0}
            y={325}
            orientation='horizontal'
            gutter={20}
            style={{ labels: { fill: theme.colors.body.text } }}
            data={[
              { name: 'Market avg.', symbol: { fill: theme.colors.dawn.hex } },
              { name: 'Current contract', symbol: { fill: theme.colors.mango.hex } },
              { name: 'New contract', symbol: { fill: theme.colors.jungle.hex } },
            ]}
          />
        </VictoryChart>
      </ChartWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
`
const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1 0 100%;
`
const Sidebar = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 365px;
  height: 100%;
  margin-right: 50px;

  .submitButton {
    margin-top: 40px;
  }
`
const DetailsWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row wrap;
  width: 100px;
  height: 225px;
  margin-left: 40px;
  justify-content: flex-start;

  h3 {
    flex: 1 0 100%;
  }
`
const Detail = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  margin-bottom: 20px;

  span {
    &:first-child {
      font-size: 24px;
    }

    &:last-child {
      font-size: 12px;
    }

    &.currency {
      &::after {
        display: inline;
        content: '€';
        position: relative;
        font-size: 1rem;
      }
    }
  }
`
const ChartWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 0 calc(365px - 50%);
  align-items: flex-start;
  height: 325px;

  p {
    margin-top: 0;
    font-weight: 500;
    font-stretch: condensed;
  }
`
