import { useReducer } from 'react'

import i18n from 'i18n'

import { WorkingDaysLong, WorkingDaysShort } from 'types'

type Action =
  | { type: 'setModalState'; payload: 'open' | 'closed' }
  | { type: 'setContactOptions'; payload: ContactOption[] }
  | { type: 'setOpenHours'; payload: OpenHour[] }
  | { type: 'setSpecificWorkingHours'; payload: boolean }
  | { type: 'setWorkingDays'; payload: 'short' | 'long' }

type State = {
  contactModalState: 'closed' | 'open'
  contactOptions: ContactOption[]
  openHours: OpenHour[]
  specificWorkingHours: boolean
  workingDays: WorkingDay[]
}

interface WorkingDay {
  label: string
  value: string
}

type ContactOption = {
  label: string
  value: ContactOptionValue
}

type ContactOptionValue = {
  id: string
  isOwner?: boolean
}

export type OpenHour = {
  day: string
  from?: string
  isOpen: boolean
  to?: string
}

const workingDaysShort = [
  {
    label: i18n.t('clusters.workingDaysShort.monFri'),
    value: WorkingDaysShort.MON_FRI,
  },
  {
    label: i18n.t('clusters.workingDaysShort.satSun'),
    value: WorkingDaysShort.SAT_SUN,
  },
]
const workingDaysLong = [
  {
    label: i18n.t('clusters.workingDaysLong.monday'),
    value: WorkingDaysLong.MONDAY,
  },
  {
    label: i18n.t('clusters.workingDaysLong.tuesday'),
    value: WorkingDaysLong.TUESDAY,
  },
  {
    label: i18n.t('clusters.workingDaysLong.wednesday'),
    value: WorkingDaysLong.WEDNESDAY,
  },
  {
    label: i18n.t('clusters.workingDaysLong.thursday'),
    value: WorkingDaysLong.THURSDAY,
  },
  {
    label: i18n.t('clusters.workingDaysLong.friday'),
    value: WorkingDaysLong.FRIDAY,
  },
  {
    label: i18n.t('clusters.workingDaysLong.saturday'),
    value: WorkingDaysLong.SATERDAY,
  },
  {
    label: i18n.t('clusters.workingDaysLong.sunday'),
    value: WorkingDaysLong.SUNDAY,
  },
]

const openHours = [
  { day: WorkingDaysLong.MONDAY, isOpen: false, from: '', to: '' },
  { day: WorkingDaysLong.TUESDAY, isOpen: false, from: '', to: '' },
  { day: WorkingDaysLong.WEDNESDAY, isOpen: false, from: '', to: '' },
  { day: WorkingDaysLong.THURSDAY, isOpen: false, from: '', to: '' },
  { day: WorkingDaysLong.FRIDAY, isOpen: false, from: '', to: '' },
  { day: WorkingDaysLong.SATERDAY, isOpen: false, from: '', to: '' },
  { day: WorkingDaysLong.SUNDAY, isOpen: false, from: '', to: '' },
]

const initialState: State = {
  contactModalState: 'closed',
  contactOptions: [],
  openHours: [],
  specificWorkingHours: false,
  workingDays: [],
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setModalState':
      return {
        ...state,
        contactModalState: action.payload,
      }
    case 'setContactOptions':
      return {
        ...state,
        contactOptions: action.payload,
      }
    case 'setOpenHours':
      return {
        ...state,
        openHours: action.payload,
      }
    case 'setSpecificWorkingHours':
      return {
        ...state,
        specificWorkingHours: action.payload,
        openHours: action.payload ? openHours : [],
      }
    case 'setWorkingDays': {
      const workingDays = action.payload === 'short' ? workingDaysShort : workingDaysLong

      return {
        ...state,
        workingDays: state.specificWorkingHours ? workingDays : [],
      }
    }
    default:
      return initialState
  }
}

export const useClusterFormState = () => useReducer(reducer, initialState)
