import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'
import styled from 'styled-components'

import { useAppData } from 'context'
import { getFacility } from 'services'

interface Props {
  links?: Link[] | []
}

type Link = {
  path: string
  label: string
}

interface NavLinkProps {
  activeclassname?: string
}

export const Navbar: React.FC<Props> = ({ links }) => {
  const { tool } = useAppData()
  const { facilityId } = useParams()
  const { data: facility } = useQuery(['facility'], () => getFacility(facilityId), {
    enabled: !!facilityId,
  })

  return (
    <Nav>
      <NavItems>
        {links?.map((link, i) => (
          <NavItem key={i}>
            <StyledNavLink to={link.path} activeclassname='active'>
              {link.label}
            </StyledNavLink>
          </NavItem>
        ))}
        {tool === 'care' && <NavItem className='facility-name'>{facility?.name}</NavItem>}
      </NavItems>
    </Nav>
  )
}

const Nav = styled.nav`
  position: relative;
  align-self: flex-end;
  height: 100%;
  flex-grow: 1;
`

const NavItems = styled.ul`
  margin: 0;
  padding: 0 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  list-style: none;
`

const NavItem = styled.li`
  button {
    display: flex;
    align-items: center;
    height: 50px;
    margin: 10px 10px 0;
    padding: 0 18px 0;
    background: none;
    color: ${({ theme }) => theme.colors.header.text};
    font-weight: 500;
  }

  &.facility-name {
    display: flex;
    align-items: center;
    height: 60px;
    margin: 0 0 0 20px;
    padding: 0 18px 0;
    background: none;
    color: ${({ theme }) => theme.colors.header.text};
    font-weight: 500;
    font-stretch: condensed;
  }
`

const StyledNavLink = styled(NavLink)<NavLinkProps>`
  display: flex;
  align-items: center;
  height: 50px;
  margin: 10px 10px 0;
  padding: 0 18px 0;
  background-color: ${({ theme }) => theme.colors.header.background};
  color: ${({ theme }) => theme.colors.header.text};
  transition: background-color 250ms ease;

  &.${(props) => props.activeclassname} {
    background-color: ${({ theme }) => theme.colors.body.background};
    border-radius: 4px 4px 0 0;
  }
`
