import React, { ChangeEvent, useEffect, useState } from 'react'

import { Field } from 'formik'
import styled from 'styled-components'

import { CaptionLeftNegative } from 'views'

interface Props {
  value?: string
  label?: string
  maxLength?: number
  name: string
  type?: string
  error?: string
  handleChange: (e: ChangeEvent) => void
}

export const AnimatedInput: React.FC<Props> = ({
  label,
  name,
  maxLength,
  error,
  type = 'text',
  handleChange,
}) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState(ref.current?.value || null)

  const checkValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, maxLength)

    setInputValue(value)
    handleChange(e)
  }

  useEffect(() => {
    ref.current ? setInputValue(ref.current.value) : setInputValue(null)
    handleChange({ target: ref.current } as ChangeEvent<HTMLInputElement>)
  }, [ref.current?.value])

  return (
    <Container className={inputValue !== '' ? 'shrink animatedLabelInput' : 'animatedLabelInput'}>
      <Field
        id={name}
        innerRef={ref}
        name={name}
        maxLength={maxLength}
        type={type}
        onChange={checkValue}
        className={error ? 'error' : ''}
      />
      <Label htmlFor={name}>{label}</Label>
      {error ? <CaptionLeftNegative>{error}</CaptionLeftNegative> : null}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  position: relative;

  input[type='text'],
  input[type='password'] {
    position: relative;
    padding: 18px 12px 8px !important;
    flex-grow: 1;

    &:focus + label {
      transform: translate(12px, 5px) scale(0.6);
    }
  }

  &.shrink {
    label {
      transform: translate(12px, 5px) scale(0.6);
    }
  }
`

const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.colors.inputs.text};
  transform: translate(10px, 14px) scale(1);
  transform-origin: top left;
  transition: 250ms transform ease;
`
