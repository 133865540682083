import { Contact } from 'types'

import http from './http-common'

export const getContacts = (facilityId: string): Promise<Contact[]> =>
  http.get(`/facility/${facilityId}/contact`).then((response) => response.data)

export const newContact = (facilityId: string, contact: Contact): Promise<string> =>
  http.post(`/facility/${facilityId}/contact`, contact).then((response) => response.data)

export const updateContact = (facilityId: string, contact: Contact): Promise<string> =>
  http
    .put(`/facility/${facilityId}/contact/${contact.id}`, contact)
    .then((response) => response.data)

export const deleteContact = (contactId: string): Promise<string> =>
  http.delete(`/facility/contact/${contactId}`).then(() => `Deleted contact, id: ${contactId}`)
