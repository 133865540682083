import { Cluster } from 'types'

import http from './http-common'

export const getClusters = (facilityId: string): Promise<Cluster[]> =>
  http.get(`/facility/${facilityId}/cluster`).then((response) => response.data)

export const newCluster = (facilityId: string, cluster: Cluster): Promise<string> =>
  http.post(`/facility/${facilityId}/cluster`, cluster).then((response) => response.data)

export const updateCluster = (
  facilityId: string,
  clusterId: string,
  cluster: Cluster,
): Promise<string> =>
  http
    .put(`/facility/${facilityId}/cluster/${clusterId}`, cluster)
    .then((response) => response.data)

export const deleteCluster = (clusterId: string): Promise<string> =>
  http.delete(`/facility/cluster/${clusterId}`).then(() => `Deleted cluster, id: ${clusterId}`)
