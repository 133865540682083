import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { DarkMode, LightMode } from '@mui/icons-material'
import { Switch } from '@mui/material'
import styled from 'styled-components'

import { useAppData } from 'context'
import { cardBody, cardContainer } from 'styles'

export const Home: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { theme, dispatch } = useAppData()

  return (
    <>
      <Container>
        <CardContainer onClick={() => navigate('/map/facilities')}>
          <CardBody>
            <MapTitle>{t('home.mapTool')}</MapTitle>
            <span>{t('home.mapSubtitle')}</span>
          </CardBody>
        </CardContainer>
        <CardContainer onClick={() => navigate('/costs/facilities')}>
          <CardBody>
            <CostsTitle>{t('home.costsTool')}</CostsTitle>
            <span>{t('home.costsSubtitle')}</span>
          </CardBody>
        </CardContainer>
        <CardContainer onClick={() => navigate('/fix/facilities')}>
          <CardBody>
            <FixTitle>{t('home.fixTool')}</FixTitle>
            <span>{t('home.fixSubtitle')}</span>
          </CardBody>
        </CardContainer>
        <CardContainer onClick={() => navigate('/docs/facilities')}>
          <CardBody>
            <DocsTitle>{t('home.docsTool')}</DocsTitle>
            <span>{t('home.docsSubtitle')}</span>
          </CardBody>
        </CardContainer>
        <CardContainer onClick={() => navigate('/care/facilities')}>
          <CardBody>
            <CareTitle>{t('home.careTool')}</CareTitle>
            <span>{t('home.careSubtitle')}</span>
          </CardBody>
        </CardContainer>
        <LinksContainer>
          <Links>
            <ul>
              <li>
                <a href='https://www.unica.nl/'>
                  <span>Unica</span>
                </a>
              </li>
              <li>
                <a href='https://www.unica.nl/privacy_statement'>
                  <span>Privacy</span>
                </a>
              </li>
              <li>
                <a href='https://building-insight.nl/conditions/Unica%20-%20Algemene%20Voorwaarden%20Webshop%20-%2020201130.pdf'>
                  <span>Terms</span>
                </a>
              </li>
              <li>
                <ThemeSwitcher>
                  <LightMode className={theme === 'dark' ? 'subtle lightmode' : 'lightmode'} />
                  <Switch
                    onChange={() =>
                      dispatch({ payload: theme === 'dark' ? 'light' : 'dark', type: 'setTheme' })
                    }
                    checked={theme === 'dark'}
                  />
                  <DarkMode className={theme === 'light' ? 'subtle darkmode' : 'darkmode'} />
                </ThemeSwitcher>
              </li>
            </ul>
          </Links>
        </LinksContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1440px;
  margin: 50px 0;
  justify-content: center;

  > div {
    height: 220px;
  }
`

const CardContainer = styled.div`
  ${cardContainer}

  border-radius: 0;
  padding-left: 40px;

  h1 {
    font-size: 48px;
    margin-bottom: 5px;
  }
  background-color: ${({ theme }) => theme.colors.card.backgroundAlt};
`

const CardBody = styled.div`
  ${cardBody}
`

const LinksContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 20px;
`

const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  justify-content: flex-start;
  align-items: end;

  ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0 10px;
    padding: 0;
    a {
      transition: all 0.2s ease-in-out;
      &:hover {
        color: ${({ theme }) => theme.colors.mangoLight.hex};
      }
    }
  }
`

const MapTitle = styled.h1`
  color: ${({ theme }) => theme.colors.mangoLight.hex};
`
const CostsTitle = styled.h1`
  color: ${({ theme }) => theme.colors.dawnLight.hex};
`
const FixTitle = styled.h1`
  color: ${({ theme }) => theme.colors.berryLight.hex};
`

const DocsTitle = styled.h1`
  color: ${({ theme }) => theme.colors.greyScale.lighter.hex};
`

const CareTitle = styled.h1`
  color: ${({ theme }) => theme.colors.mantis.hex};
`

const ThemeSwitcher = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 50px;

  .subtle {
    opacity: 0.5;
  }

  .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.colors.inputs.switch.fill};
  }

  .MuiSwitch-switchBase {
    &.Mui-checked {
      .MuiSwitch-thumb {
        background-color: ${({ theme }) => theme.colors.mango.hex};
      }

      & + .MuiSwitch-track {
        background-color: ${({ theme }) => theme.colors.mango.hex};
        opacity: 1;
      }
    }
  }
`
