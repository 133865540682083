import { Form } from 'formik'
import styled from 'styled-components'

interface FormGroupProps {
  width?: 'narrow' | 'wide'
}

export const FormGroup = styled.div<FormGroupProps>`
  display: flex;
  flex-direction: column;
  margin: 0 0 40px 0;

  width: ${({ width }) => {
    switch (width) {
      case 'narrow':
        return '375px'
      case 'wide':
        return '750px'
      default:
        return '100%'
    }
  }};
`

export const FormHeader = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  top: 60px;
  left: -50vw;
  width: 100vw;
  height: 75px;
  margin-left: 50%;
  padding: 0;
  background-color: ${({ theme }) => `rgba(${theme.colors.body.backgroundRGB}, 0.5)`};
  backdrop-filter: blur(5px);
  z-index: 20;

  > h1 {
    display: block;
    width: 1320px;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0 60px;
  }
`

export const FormFooter = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  bottom: 0;
  left: -50vw;
  width: 100vw;
  height: 85px;
  margin-left: 50%;
  padding: 0 60px;
  background-color: ${({ theme }) => `rgba(${theme.colors.body.backgroundRGB}, 0.5)`};
  backdrop-filter: blur(5px);
  z-index: 20;

  button {
    width: 200px;
    margin-right: 15px;
  }

  > div {
    width: 1320px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
`

export const FormStep = styled.div`
  display: none;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  transition: 250ms all ease;
  z-index: 5;

  &.fade-next {
    display: flex;
    opacity: 0;
    transform: translateX(50px);
  }

  &.fade-prev {
    display: flex;
    opacity: 0;
    transform: translateX(-50px);
  }

  &.fade-in {
    opacity: 1;
    transform: translateX(0);
    z-index: 10;
  }

  &.fade-out-next {
    opacity: 0;
    transform: translateX(-50px);
  }

  &.fade-out-prev {
    opacity: 0;
    transform: translateX(50px);
  }
`

export const StyledForm = styled(Form)`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  margin: 30px 0 30px 0;
  padding-bottom: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  &:has(${FormHeader}) {
    margin-top: 0;
    padding-top: 75px;

    ${FormStep} {
      top: 75px;
    }
  }

  &:has(${FormFooter}) {
    margin-bottom: 0;
    padding-bottom: 85px;

    ${FormStep} {
      padding-bottom: 85px;
    }
  }

  input[type='text'],
  textarea {
    padding: 12px 12px 14px;
    background-color: ${({ theme }) => theme.colors.inputs.background};
    color: ${({ theme }) => theme.colors.inputs.text};
    border: none;
    border-radius: 2px;

    transition: box-shadow 0.2s ease, color 0.2s ease, background-color 0.2s ease;

    &.light {
      color: ${({ theme }) => theme.colors.body.text};
    }

    &:placeholder {
      color: ${({ theme }) => theme.colors.inputs.placeholder};
    }

    &:focus-visible {
      outline: none;
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.body.backgroundAlt};
    }

    &.error {
      box-shadow: inset 0 0 0 3px ${({ theme }) => theme.colors.pynk.hex};
    }
  }

  h1,
  h3 {
    margin: 15px 0;
  }

  input[type='text'] {
    width: 100%;
    height: 22px;
    margin-bottom: 15px;

    &.inlineField {
      background-color: transparent;
      padding: 0;
      color: ${({ theme }) => theme.colors.mango.hex};
      border: none;
      text-align: right;

      &::placeholder {
        color: ${({ theme }) => theme.colors.mango.hex};
      }
    }
  }

  textarea {
    width: 560px;
    height: 160px;
    padding: 17px 15px 99px;
    resize: none;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  textarea[name='message'] {
    height: 125px;
  }
`
