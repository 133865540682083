import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import { ReactComponent as CloseIcon } from 'assets/close-icon.svg'
import { ReactComponent as GasIcon } from 'assets/gas-icon.svg'
import { ReactComponent as GearIcon } from 'assets/gear-icon.svg'
import { ReactComponent as PowerIcon } from 'assets/power-icon.svg'
import { ReactComponent as ServiceIcon } from 'assets/service-icon.svg'
import { ReactComponent as WaterIcon } from 'assets/water-icon.svg'
import { DropdownItem, DropdownMenu } from 'components/Dropdown'
import { useAppData } from 'context'
import { useOutsideClick } from 'hooks'
import {
  cardBody,
  cardContainer,
  cardDetailItem,
  cardDetailList,
  cardFooter,
  cardHeader,
} from 'styles'
import { Action } from 'types'

interface Props {
  action: Action
  number?: number
  total?: number
}

export const ActionCard: React.FC<Props> = ({ action, number, total }) => {
  const { tool, view } = useAppData()
  const navigate = useNavigate()
  const [showDropdown, setShowDropdown] = useState(false)

  const handleOutsideClick = () => {
    setShowDropdown(false)
  }

  const dropdownRef = useOutsideClick(handleOutsideClick)

  const EmissionIcon = React.useMemo(() => {
    switch (action.emissionCategory) {
      case 'Power':
        return <PowerIcon />
      case 'Water':
        return <WaterIcon />
      case 'Gas':
        return <GasIcon />
      case 'Service':
        return <ServiceIcon />
      case 'Operation':
        return <GearIcon />
      case 'Waste':
        return <CloseIcon />
      default:
        return null
    }
  }, [action.emissionCategory])

  return (
    <CardContainer className={`${view}`} number={number} total={total}>
      <CardHeader>
        {EmissionIcon}
        <DetailList>
          <DetailItem>{action.emissionCategory}</DetailItem>
          <DetailItem>{action.uniqueIdentifier}</DetailItem>
          <DetailItem>{action.subcategory}</DetailItem>
        </DetailList>
        <DropdownMenu innerRef={dropdownRef} show={showDropdown} setShow={setShowDropdown}>
          <DropdownItem name='edit' action={() => false} />
          <DropdownItem name='delete' style='danger' action={() => false} />
        </DropdownMenu>
      </CardHeader>
      <CardBody>
        <h3
          onClick={() => navigate(`/${tool}/facilities/${action.facilityId}/actions/${action.id}`)}
        >
          {action.title}
        </h3>
      </CardBody>
      <CardFooter>
        {action.startDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })}
        {' - '}
        {action.endDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })}
      </CardFooter>
    </CardContainer>
  )
}

interface CardProps {
  number?: number
  total?: number
}

const CardContainer = styled.div<CardProps>`
  ${cardContainer}

  flex-flow: column wrap;
`
const CardHeader = styled.div`
  ${cardHeader}

  justify-content: flex-start;
  margin-bottom: 14px;

  .dropDown {
    height: 24px;

    .icon {
      height: 24px;
    }
  }
`
const CardBody = styled.div`
  ${cardBody}

  h3 {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 1rem;
    font-weight: 500;
    font-stretch: condensed;
    -webkit-line-clamp: 2;
  }
`
const CardFooter = styled.div`
  ${cardFooter}
`
const DetailList = styled.ul`
  ${cardDetailList}

  width: 100%;
  margin: 0 0 0 18px;
  font-size: 0.67rem;
`
const DetailItem = styled.li`
  ${cardDetailItem}

  &::after {
    --width: 3px;
    --height: 3px;
  }

  &:last-child {
    position: relative;
    width: 100%;
    margin: 0;
    /* left: 28px; */
    /* top: 35px; */

    &::after {
      display: none;
    }
  }
`
