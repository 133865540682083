import { css } from 'styled-components'

interface CardProps {
  number?: number
  total?: number
}

export const cardContainer = css<CardProps>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.card.background};
  border-radius: ${({ theme }) => theme.card.borderRadius}px;
  box-shadow: none;
  align-items: center;
  opacity: 0;
  transform: scale(1);
  transition: margin 300ms ease, box-shadow 300ms ease, transform 300ms ease;
  animation: 300ms forwards fadeIn ease-in;
  animation-delay: ${({ number }) => `${number++ * 50}ms`};
  z-index: ${({ number, total }) => total - number};

  &.fadeOut {
    animation: 300ms backwards fadeOut ease-out;
    animation-delay: ${({ number, total }) => `${(total - number) * 50}ms`};
  }

  &:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.03);
    cursor: pointer;
  }

  @keyframes fadeIn {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 100%;
      transform: scale(1);
    }
    to {
      opacity: 0%;
      transform: scale(0.97);
    }
  }
`

export const cardHeader = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const cardBody = css`
  display: flex;
  flex-flow: column wrap;

  h3 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
export const cardFooter = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`
export const cardImageWrapper = css`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  margin-right: 20px;
  text-align: center;
  border-radius: 0px;

  img {
    height: 90px;
    width: 90px;
    object-fit: cover;
    border-radius: inherit;
  }
`

export const cardImageAvatar = css`
  position: absolute;
  right: -3px;
  bottom: -3px;
  width: 52px;
  height: 52px;
  background-color: ${({ theme }) => theme.colors.card.avatar};
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
`

export const cardDetailList = css`
  display: flex;
  flex-flow: row wrap;
  margin: 8px 0 0;
  padding: 0;
  list-style-type: none;
`

export const cardDetailItem = css`
  --width: 5px;
  --height: 5px;

  &.highlight {
    color: ${({ theme }) => theme.colors.card.highlight};
    font-weight: 500;
  }

  &.subtle {
    color: ${({ theme }) => theme.colors.card.subtle};
  }

  &:not(:first-child) {
    position: relative;
    margin-left: 25px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - calc(var(--height) / 2));
      left: -15px;
      width: var(--width);
      height: var(--height);
      background-color: ${({ theme }) => theme.colors.body.text};
      border-radius: 50%;
    }
  }
`
