import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'
import styled from 'styled-components'

import { Button, CardSkeleton, PolicyCard, Toolbar } from 'components'
import { useAppData } from 'context'
import { getPolicyTemplates } from 'services'

interface GridProps {
  view?: string
}

export const PoliciesOverview: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { sortOrder, view, dispatch } = useAppData()
  const { data: policies, isLoading } = useQuery(['policies'], getPolicyTemplates)
  const [fadeOut, setFadeOut] = useState('')

  const sortPolicies = (order: typeof sortOrder) => {
    setFadeOut('fadeOut')
    setTimeout(() => {
      policies?.sort((a, b) =>
        order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
      )

      dispatch({ type: 'setSortOrder', payload: order })
      setFadeOut('')
    }, policies?.length * 50 + 150)
  }

  return (
    <>
      <TopBar>
        <h1>{t('policies.header')}</h1>
        <Toolbar>
          <Button
            onClick={() => sortPolicies(sortOrder === 'asc' ? 'desc' : 'asc')}
            icon={sortOrder === 'asc' ? 'south' : 'north'}
            text='AZ'
          />
          <Button link='/map/policies/new' icon='add' />
        </Toolbar>
      </TopBar>

      <PoliciesGrid view={view}>
        {isLoading ? (
          <CardSkeleton amount={5} />
        ) : (
          policies
            .sort((a, b) =>
              sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
            )
            .map((policy, i) => {
              return (
                <PolicyCard
                  key={policy.id}
                  className={fadeOut}
                  policy={policy}
                  number={i}
                  total={policies.length}
                />
              )
            })
        )}
      </PoliciesGrid>
    </>
  )
}
const TopBar = styled.div`
  display: flex;
  margin-bottom: 38px;
  align-items: center;
`

const PoliciesGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
`
