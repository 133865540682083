import React from 'react'

import { Field } from 'formik'
import styled from 'styled-components'

interface Props {
  label?: string
  name: string
  pills: Pill[]
  type: 'checkbox' | 'radio'
}

interface Pill {
  label: string
  value: string | number
}

export const PillSelector: React.FC<Props> = ({ label, name, pills, type }) => {
  return (
    <OuterWrapper role='group'>
      {label && <label>{label}</label>}
      {pills.map((pill) => (
        <LabelWrapper key={pill.value}>
          <Field type={type} name={name} value={pill.value} id={pill.value} />
          <label htmlFor={String(pill.value)}>{pill.label}</label>
        </LabelWrapper>
      ))}
    </OuterWrapper>
  )
}

const OuterWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 15px;

  > label {
    margin: 0 15px 5px 0;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  color: white;
  transition: transform 250ms ease;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  label {
    margin: 0 10px 10px 0;
    padding: 10px 13px 8px 15px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.button.background};
    color: ${({ theme }) => theme.colors.body.text};
    cursor: pointer;
    transition-property: background-color color;
    transition: 250ms ease;
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none;

    &:checked + label {
      background-color: ${({ theme }) => theme.colors.body.primary};
      color: ${({ theme }) => theme.colors.button.text};
    }
  }
`
