import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'
import styled, { useTheme } from 'styled-components'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryLine,
  VictoryTheme,
} from 'victory'

import { ActionCard, Button } from 'components'
import { getFacility } from 'services'
import { actionsDummy } from 'services/actions.dummy'
import { Action, Facility } from 'types'

interface Props {
  facilityId?: string
  isActive?: string
}

const getMinMaxDate = (actions: Action[]) => {
  const dates = actions.map((action: Action) => {
    return [action.startDate.getTime(), action.endDate.getTime()]
  })

  const flatDates = dates.flat()
  const minDate = new Date(Math.min(...flatDates))
  const maxDate = new Date(Math.max(...flatDates))

  return [minDate, maxDate]
}

const makeDateRange = (startDate: Date, endDate: Date): Date[] => {
  const startYear = startDate.getFullYear()
  const endYear = endDate.getFullYear()
  const endDay = endDate.getDate()
  const dates = []

  for (let i = startYear; i <= endYear; i++) {
    const startMonth = i === startYear ? startDate.getMonth() : 0
    const endMonth = i !== endYear ? 11 : endDate.getMonth()

    for (let j = startMonth; j <= endMonth; j++) {
      dates.push(new Date(i, j, 1))

      if (i === endYear && j === endMonth && endDay > 1) {
        dates.push(new Date(i, j + 1, 1))
      }
    }
  }

  return dates
}

const makeConsumptionRange = (max: number) => {
  const min = 40
  const steps = 10
  const step = Math.round(max / steps) - 1
  const range = []

  for (let i = 0; i < steps; i++) {
    const num = min + i * step
    range.push(num)

    if (num >= max) break
  }

  return range
}

const getFootprint = (total: number, actions: Action[], dates: Date[]) => {
  const footprint = dates.map((date: Date) => {
    const relevant = actions.filter((action: Action) => {
      if (date >= action.endDate) return true
    })

    actions = actions.filter((action: Action) => {
      if (date < action.endDate) return true
    })

    return {
      date: date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
      footprint: (total -= relevant.reduce((acc, curr) => acc + curr.kwhSavedYearly, 0)),
    }
  })

  return footprint
}

export const FacilityActionsTab: React.FC<Props> = ({ facilityId }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [chartWrapper, setChartWrapper] = React.useState<DOMRect>(null)
  const [dateRange, setDateRange] = React.useState<Date[]>([])
  const [consumptionRange, setConsumptionRange] = React.useState<number[]>([])
  const [footprint, setFootprint] = React.useState<{ date: string; footprint: number }[]>([])
  const [totalCosts, setTotalCosts] = React.useState<number>(0)

  const { data: facility } = useQuery(['facility', facilityId], () => getFacility(facilityId), {
    enabled: !!facilityId,
    onSuccess: (facility: Facility) => {
      // hardcode for now
      facility.fossilConsumption = 180
    },
  })

  const { data: actions } = useQuery(['actions', facilityId], (): Action[] => actionsDummy, {
    enabled: !!facilityId,
    onSuccess: (actions: Action[]) => {
      actions.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
    },
  })

  const chartRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setChartWrapper(node.getBoundingClientRect())
    }
  }, [])

  useEffect(() => {
    if (!actions || !facility) return

    const dates = getMinMaxDate(actions)

    setTotalCosts(actions.reduce((acc, curr) => acc + curr.cost, 0))
    setDateRange(makeDateRange(dates[0], dates[1]))
    setConsumptionRange(makeConsumptionRange(facility.fossilConsumption || 0))
    setFootprint(
      getFootprint(facility.fossilConsumption || 0, actions, makeDateRange(dates[0], dates[1])),
    )
  }, [actions, facility])

  return (
    <>
      <Header>
        <h1>{t('care.actions')}</h1>
        <Button link={`/map/facilities/${facilityId}/clusters/new`} icon='add' />
      </Header>

      {facility && actions && consumptionRange.length > 0 && (
        <ChartWrapper ref={chartRef}>
          <VictoryChart
            domainPadding={10}
            width={chartWrapper?.width || 0}
            height={chartWrapper?.height || 0}
            theme={VictoryTheme.material}
          >
            {dateRange.length > 0 && (
              <VictoryAxis
                tickValues={dateRange.map((date: Date) => date.getTime())}
                tickFormat={(x) =>
                  new Date(x).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })
                }
                style={{
                  tickLabels: { fill: theme.colors.body.text },
                  grid: { stroke: theme.colors.body.background, strokeDasharray: 0 },
                }}
              />
            )}
            <VictoryAxis
              dependentAxis
              tickValues={consumptionRange}
              tickFormat={(x) => x}
              style={{
                tickLabels: { fill: theme.colors.body.text },
                grid: { stroke: theme.colors.body.background, strokeDasharray: 0 },
              }}
            />
            <VictoryLine
              data={footprint}
              x='date'
              y='footprint'
              style={{ data: { stroke: theme.colors.pynk.hex } }}
            />
            <VictoryLegend
              x={chartWrapper?.width - 350 || 0}
              y={0}
              orientation='horizontal'
              gutter={20}
              width={330}
              style={{ labels: { fill: theme.colors.body.text } }}
              data={[
                { name: 'Care actions', symbol: { fill: theme.colors.leaf.hex } },
                { name: 'Policy maintenance', symbol: { fill: theme.colors.dawn.hex } },
                { name: 'Footprint', symbol: { fill: theme.colors.pynk.hex } },
              ]}
            />
          </VictoryChart>
          <VictoryChart
            domainPadding={10}
            width={chartWrapper?.width || 0}
            height={chartWrapper?.height || 0}
            theme={VictoryTheme.material}
          >
            {dateRange.length > 0 && (
              <VictoryAxis
                tickValues={dateRange.map((date: Date) => date.getTime())}
                tickFormat={(x) =>
                  new Date(x).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })
                }
                style={{
                  tickLabels: { fill: theme.colors.body.text },
                  grid: { stroke: theme.colors.body.background, strokeDasharray: 0 },
                }}
              />
            )}
            <VictoryAxis
              dependentAxis
              tickValues={consumptionRange}
              tickFormat={(x) => x}
              style={{
                tickLabels: { fill: theme.colors.body.text },
                grid: { stroke: theme.colors.body.background, strokeDasharray: 0 },
              }}
            />
            {actions.map((action: Action, i) => (
              <VictoryBar
                key={i}
                alignment='start'
                barWidth={() => {
                  const diff = Math.ceil(
                    (action.endDate.getTime() - action.startDate.getTime()) / (1000 * 3600 * 24),
                  )

                  return chartWrapper ? (chartWrapper?.width / dateRange.length) * (diff / 30) : 0
                }}
                data={[
                  {
                    x: action.startDate.getTime(),
                    y: facility.fossilConsumption - i * ((action.cost / totalCosts) * 40 + 15),
                  },
                ]}
                y0={(d) => d.y - (action.cost / totalCosts) * 50}
                style={{ data: { fill: theme.colors.leaf.hex } }}
              />
            ))}
          </VictoryChart>
        </ChartWrapper>
      )}

      {actions && (
        <ActionsGrid>
          {actions.map((action, i) => (
            <ActionCard key={action.id} action={action} number={i} total={actions.length} />
          ))}
        </ActionsGrid>
      )}
    </>
  )
}

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const ActionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
`
const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;

  p {
    margin-top: 0;
    font-weight: 500;
    font-stretch: condensed;
  }

  .VictoryContainer:nth-child(2) {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: -1;

    svg {
      g[role='presentation']:nth-child(1),
      g[role='presentation']:nth-child(2) {
        display: none;
      }
    }
  }
`
