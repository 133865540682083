import React from 'react'

import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

export const Topbar: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

const Container = styled.div`
  display: flex;
  margin: 50px 0 38px 0;
  margin-bottom: 38px;
  justify-content: start;
`
