import { useQuery } from '@tanstack/react-query'

import { getClusters, getDocuments, getRequests } from 'services'
import { Cluster, Document, Facility, Tool } from 'types'

interface DetailItem {
  label: string
  value: string | number
  className?: string
}

const hasClusterWithoutPolicy = (clusters: Cluster[]): boolean => {
  return clusters?.some((cluster) => !cluster.policyId) || !clusters?.length
}

export const useFormatDetailList = (facility: Facility, tool: Tool): DetailItem[] => {
  const { data: clusters } = useQuery(['clusters', facility.id], () => getClusters(facility.id))
  const { data: requests } = useQuery(['requests', facility.id], () => getRequests(facility.id))
  const { data: documents } = useQuery(['documents', facility.id], () => getDocuments(facility.id))

  switch (tool) {
    case 'map': {
      return [
        { label: 'energyLabel', value: facility.energyLabel || '' },
        { label: 'category', value: facility.categories[0] || '' },
        {
          label: 'clusters',
          value: clusters?.length || 0,
          className: hasClusterWithoutPolicy(clusters) ? 'highlight' : '',
        },
        { label: 'policyScore', value: (Math.random() * (10 - 6) + 6).toFixed(1) },
      ]
    }
    case 'fix': {
      return [
        {
          label: 'requests',
          value:
            requests?.length === 0
              ? 'No requests'
              : requests?.length > 1
              ? `${requests?.length} requests`
              : `${requests?.length} request`,
          className: requests?.length === 0 ? 'subtle' : '',
        },
      ]
    }
    case 'docs': {
      return [
        {
          label: 'documents',
          value:
            documents?.length === 0
              ? 'No documents'
              : documents?.length > 1
              ? `${documents?.length} docs`
              : `${documents?.length} doc`,
        },
        {
          label: 'new',
          value:
            documents?.filter((doc: Document) => doc.status === 'New').length === 0
              ? ''
              : `${documents?.filter((doc: Document) => doc.status === 'New').length} new`,
          className:
            documents?.filter((doc: Document) => doc.status === 'New').length === 0
              ? ''
              : 'highlight',
        },
      ]
    }
    case 'costs': {
      return [
        { label: 'placeHolder', value: 'Figure' },
        { label: 'placeHolder', value: 'Figure' },
      ]
    }
    case 'care': {
      return [
        { label: 'energyLabel', value: facility.energyLabel || '' },
        { label: 'category', value: facility.categories[0] || '' },
        { label: 'placeHolder', value: 'XX' },
      ]
    }
  }
}
