import React from 'react'

import styled from 'styled-components'

interface Props {
  values: Value[]
}

interface Value {
  label: string
  value: number
  fill?: string
  width?: number
  height?: number
}

interface ValueProps {
  width: number
  height: number
  fill: string
}

const formatter = new Intl.NumberFormat('en-US', { notation: 'compact' })

export const ValuesBox: React.FC<Props> = ({ values }) => {
  values.sort((a, b) => b.value - a.value)

  if (values.length > 4) {
    const rest = values.slice(4)
    const restTotal = rest.reduce((prev, curr) => prev + curr.value, 0)

    values.length = 4
    values.push({ label: 'Rest', value: restTotal })
  }

  values = values.map(({ label, value, fill }: Value, i) => {
    let width, height

    switch (values.length) {
      case 1:
        width = 100
        height = 100
        break
      case 2:
        width = 100
        height = 50
        break
      case 3:
        width = i === 0 ? 100 : 50
        height = 50
        break
      case 4:
        width = i === 0 ? 100 : 50
        height = i > 1 ? 25 : 50
        break
      default:
        width = i === 0 || i === 4 ? 100 : 50
        height = i > 1 ? 20 : 40
        break
    }

    return {
      value,
      label,
      fill,
      width,
      height,
    }
  })

  return (
    <ValueBoxWrapper>
      {values.map(({ label, value, fill, width, height }, i) => (
        <ValueBoxElement width={width} height={height} fill={fill} key={i}>
          <span>{formatter.format(value)}</span>
          <span>{label}</span>
        </ValueBoxElement>
      ))}
    </ValueBoxWrapper>
  )
}

const ValueBoxWrapper = styled.div`
  display: block;
  width: 225px;
  height: 225px;
`

const ValueBoxElement = styled.div<ValueProps>`
  --gap: 2px;

  display: inline-flex;
  width: ${({ width }) => `calc(${width}% - (var(--gap) * 2))`};
  height: ${({ height }) => `calc(${height}% - (var(--gap) * 2))`};
  margin: var(--gap);
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, fill }) => (fill ? fill : theme.colors.body.background)};
  border-radius: 4px;
  font-weight: 300;
  float: left;

  span:first-child {
    position: relative;
    ${({ height }) => `font-size: calc(24px * (${height} / 50))`};

    &::after {
      content: '€';
      ${({ height }) => `font-size: calc(18px * (${height} / 50))`};
    }
  }

  span:last-child {
    font-size: 12px;
  }
`
