import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

interface Props {
  options: Options[]
  value: string
  handleChange: (e: string) => void
}

interface Options {
  label: string
  value: string
}

export const MaintenanceRadio: React.FC<Props> = ({ options, value, handleChange }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(1)

  useEffect(() => {
    const pos = options.map((o) => o.value).indexOf(value)
    pos > -1 ? setSelected(pos) : setSelected(1)
  }, [])

  const details = [
    [
      { label: t('clusters.brand'), value: '3/6' },
      { label: t('clusters.maintenance'), value: '3/6' },
      { label: t('clusters.energy'), value: '4/6' },
      { label: t('clusters.lawsAndRegulations'), value: '1/6' },
    ],
    [
      { label: t('clusters.brand'), value: '3/6' },
      { label: t('clusters.maintenance'), value: '2/6' },
      { label: t('clusters.energy'), value: '3/6' },
      { label: t('clusters.lawsAndRegulations'), value: '1/6' },
    ],
    [
      { label: t('clusters.brand'), value: '2/6' },
      { label: t('clusters.maintenance'), value: '2/6' },
      { label: t('clusters.energy'), value: '2/6' },
      { label: t('clusters.lawsAndRegulations'), value: '1/6' },
    ],
  ]

  const updateValues = (key: number) => {
    setSelected(key)
    handleChange(options[key].value)
  }

  return (
    <OuterWrapper>
      <OptionsWrapper>
        {options.map((option, i) => (
          <Option
            key={i}
            className={selected === i ? 'selected' : ''}
            onClick={() => updateValues(i)}
          >
            {option.label}
          </Option>
        ))}
      </OptionsWrapper>
      {(selected === 0 || 1 || 2) && (
        <DetailsWrapper>
          <h3>{options[selected].label}</h3>
          {details[selected].map((detail, i) => (
            <Detail key={i}>
              <DetailValue>{detail.value}</DetailValue>
              <DetailLabel>{detail.label}</DetailLabel>
            </Detail>
          ))}
        </DetailsWrapper>
      )}
    </OuterWrapper>
  )
}

const OuterWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 105px;
`

const DetailsWrapper = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-flow: row wrap;
  margin-left: 60px;

  h3 {
    flex: 1 100%;
  }
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  overflow: hidden;
  font-stretch: condensed;
`

const DetailValue = styled.span`
  font-size: 24px;
`

const DetailLabel = styled.span`
  font-size: 12px;
`

const Option = styled.div`
  padding: 18px 0;
  background-color: ${({ theme }) => theme.colors.button.background};
  text-align: center;
  font-stretch: condensed;
  cursor: pointer;
  transition: background-color 250ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.body.primaryLight};
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.body.primary};
    color: ${({ theme }) => theme.colors.body.background};
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`
