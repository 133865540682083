import React from 'react'
import { useParams } from 'react-router-dom'

import i18n from 'i18n'
import styled from 'styled-components'

import { TabBar, TabView } from 'components'
import { useAppData } from 'context'
import { FacilityActionsTab } from 'views'
import { Tool } from 'types'

interface Tab {
  label: string
  component: React.ReactElement
}

const getTabs = (facilityId: string, tool: Tool): Tab[] => {
  switch (tool) {
    case 'care':
      return [
        { label: i18n.t('care.overview'), component: null },
        {
          label: i18n.t('care.actions'),
          component: <FacilityActionsTab facilityId={facilityId} />,
        },
        { label: i18n.t('care.emission'), component: null },
        { label: i18n.t('care.regulations'), component: null },
      ]
  }
}

export const FacilityCareDetail: React.FC = () => {
  const { tool } = useAppData()
  const { facilityId } = useParams()
  const tabs = getTabs(facilityId, tool)

  return (
    <Container>
      {tabs && (
        <TabBar tabs={tabs.flatMap((tab) => tab.label)} active={1} disabled={[0, 2, 3]}>
          {tabs.map((tab) => (
            <TabView key={tab.label} tab={tab.label}>
              {tab.component}
            </TabView>
          ))}
        </TabBar>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .tabview {
    margin-top: -40px;
  }
`
