import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Info } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Field, Formik } from 'formik'
import i18n from 'i18n'
import debounce from 'lodash.debounce'
import mapboxgl from 'mapbox-gl'
import styled from 'styled-components'
import * as Yup from 'yup'

import { coverImage } from 'assets'
import {
  AnimatedInput,
  FormFooter,
  FormGroup,
  InputCard,
  PillSelector,
  StyledForm,
  SubmitButton,
  SwitchInput,
} from 'components'
import { nullifyEmptyStrings, useDebouncedEffect } from 'hooks'
import {
  getAddress,
  getFacility,
  getFacilityImg,
  newFacility,
  newFacilityImg,
  updateFacility,
} from 'services'
import { Address, Categories, Facility } from 'types'

// weird mapbox stuff
// eslint-disable-next-line
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('mapbox-gl/dist/mapbox-gl-csp-worker').default
mapboxgl.accessToken =
  'pk.eyJ1IjoiZGlnaXRhbGluZGl2aWR1YWwiLCJhIjoiY2w5bHliZTlzMWt5ZjN1cjc2MThlM2V5dSJ9.PVdFSZqs9FtL-Xxm9KChRA'

const FacilityValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
  suggestedCategory: Yup.string().required(),
  categories: Yup.array().required(),
  isMonument: Yup.boolean(),
  isNtaApproved: Yup.boolean(),
  location: Yup.object().shape({
    postCode: Yup.string().required(),
    buildingNumber: Yup.number().required(),
    extension: Yup.string(),
    municipality: Yup.string().required(),
    street: Yup.string().required(),
    province: Yup.string().required(),
  }),
  details: Yup.object().shape({
    year: Yup.number().nullable(),
    grossArea: Yup.number().nullable(),
    usefulArea: Yup.number().nullable(),
    rentArea: Yup.number().nullable(),
    formFactor: Yup.number().nullable(),
  }),
  energyLabel: Yup.string().required(),
})

const initialValues = (facility: Facility): Facility => ({
  name: facility?.name || '',
  description: facility?.description || '',
  suggestedCategory: facility?.suggestedCategory || 'None',
  categories: facility?.categories || [],
  isMonument: facility?.isMonument || false,
  isNtaApproved: facility?.isNtaApproved || false,
  location: {
    postCode: '',
    buildingNumber: '' as unknown as number,
    extension: '',
    municipality: '',
    street: '',
    province: '',
    ...facility?.location,
  },
  details: {
    year: facility?.details?.year || ('' as unknown as number),
    grossArea: facility?.details?.grossArea || ('' as unknown as number),
    usefulArea: facility?.details?.usefulArea || ('' as unknown as number),
    rentArea: facility?.details?.rentArea || ('' as unknown as number),
    formFactor: facility?.details?.formFactor || ('' as unknown as number),
  },
  energyLabel: facility?.energyLabel || '',
})

const categories = [
  {
    label: i18n.t('facilities.categories.agriculture'),
    value: Categories.AGRICULTURE,
  },
  {
    label: i18n.t('facilities.categories.art'),
    value: Categories.ART,
  },
  {
    label: i18n.t('facilities.categories.education'),
    value: Categories.EDUCATION,
  },
  {
    label: i18n.t('facilities.categories.healthCare'),
    value: Categories.HEALTHCARE,
  },
  {
    label: i18n.t('facilities.categories.highRisk'),
    value: Categories.HIGH_RISK,
  },
  {
    label: i18n.t('facilities.categories.mariTime'),
    value: Categories.MARITIME,
  },
  {
    label: i18n.t('facilities.categories.offices'),
    value: Categories.OFFICES,
  },
  {
    label: i18n.t('facilities.categories.openAir'),
    value: Categories.OPEN_AIR,
  },
  {
    label: i18n.t('facilities.categories.parking'),
    value: Categories.PARKING,
  },
  {
    label: i18n.t('facilities.categories.production'),
    value: Categories.PRODUCTION,
  },
  {
    label: i18n.t('facilities.categories.research'),
    value: Categories.RESEARCH,
  },
  {
    label: i18n.t('facilities.categories.residential'),
    value: Categories.RESIDENTIAL,
  },
  {
    label: i18n.t('facilities.categories.retail'),
    value: Categories.RETAIL,
  },
  {
    label: i18n.t('facilities.categories.sport'),
    value: Categories.SPORT,
  },
  {
    label: i18n.t('facilities.categories.transport'),
    value: Categories.TRANSPORT,
  },
  {
    label: i18n.t('facilities.categories.warehouse'),
    value: Categories.WAREHOUSE,
  },
  {
    label: i18n.t('facilities.categories.none'),
    value: Categories.NONE,
  },
]

const energyLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G'].map((energyLabel) => ({
  label: energyLabel,
  value: energyLabel,
}))

export const FacilityForm: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { facilityId } = useParams()
  const [location, setLocation] = useState<Address | null>(null)
  const [messageSent, setMessageSent] = useState(false)
  const [facilityImg, setFacilityImg] = useState<File>(null)
  const [previewImg, setPreviewImg] = useState(coverImage)

  const { data: facility } = useQuery<Facility>(
    ['facility', facilityId],
    () => getFacility(facilityId),
    {
      enabled: !!facilityId,
    },
  )

  useQuery(['facilityImage', facility?.id], () => getFacilityImg(facility?.id), {
    enabled: !!facility?.img && !facilityImg,
    onSuccess(data) {
      if (!facilityImg) setPreviewImg(data)
    },
  })

  const setPreviewImage = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!target.files || target.files.length === 0) return

    setFacilityImg(target.files[0])
    setPreviewImg(URL.createObjectURL(target.files[0]))
  }

  const newFacilityMutation = useMutation((values: Facility) => newFacility(values))

  const updateFacilityMutation = useMutation((values: Facility) =>
    updateFacility(facility.id, values),
  )

  const newFacilityImageMutation = useMutation((facilityId: string) =>
    newFacilityImg(facilityId, facilityImg),
  )

  const facilityMutation = facility ? updateFacilityMutation : newFacilityMutation

  const handleSubmit = (values: Facility) => {
    const facility = JSON.parse(JSON.stringify(values))
    facility.details = nullifyEmptyStrings(facility.details)
    facilityMutation.mutate(facility, {
      onSuccess: (facilityId) => {
        if (facilityImg) {
          newFacilityImageMutation.mutate(facilityId, {
            onSuccess: () => {
              setMessageSent(true)
              navigate(`/map/facilities/${facilityId}`)
            },
          })
        } else {
          setMessageSent(true)
          navigate(`/map/facilities/${facilityId}`)
        }
      },
    })
  }

  useDebouncedEffect(
    () => {
      if (!location) return

      const div = document.getElementById('map')
      const children = Array.prototype.slice.call(div?.children || [])

      children.forEach((child: HTMLElement) => {
        div.removeChild(child)
      })

      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [location.longitude, location.latitude],
        zoom: 12,
        projection: { name: 'globe' },
      })

      new mapboxgl.Marker().setLngLat([location.longitude, location.latitude]).addTo(map)
    },
    [location],
    500,
  )

  return (
    <Formik
      initialValues={initialValues(facility)}
      validationSchema={FacilityValidationSchema}
      onSubmit={(values: Facility) => handleSubmit(values)}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {({ values, isSubmitting, isValid, handleChange, setFieldValue }) => {
        const mapAddress = async (e: ChangeEvent) => {
          const { name, value } = e.target as HTMLInputElement
          const postcodeRegex = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i
          const postCode = name === 'location.postCode' ? value : values.location.postCode
          const buildingNumber =
            name === 'location.buildingNumber' ? parseInt(value) : values.location.buildingNumber
          const extension = name === 'location.extension' ? value : values.location.extension

          if (postCode && buildingNumber && postcodeRegex.test(postCode)) {
            const address = await getAddress(postCode, buildingNumber, extension)

            if (!address) return

            setFieldValue('location.street', address.street)
            setFieldValue('location.city', address.city)
            setFieldValue('location.municipality', address.municipality)
            setFieldValue('location.province', address.province)
            setFieldValue('location.latitude', address.latitude)
            setFieldValue('location.longitude', address.longitude)
            setLocation(address)
          }
        }

        const handleAddressChange = (e: ChangeEvent) => {
          handleChange(e)
          debounce(mapAddress, 500)(e)
        }

        return (
          <StyledForm>
            <h1>{t('facilities.new')}</h1>
            <FormGroup width='narrow'>
              <FormHeadingThree>1. {t('facilities.nameAndPhoto')}</FormHeadingThree>
              <InputCard
                image={previewImg}
                label={t('facilities.addName')}
                name='name'
                onFileChange={setPreviewImage}
              />
            </FormGroup>

            <FormGroup width='wide'>
              <ToolTipWrapper>
                <h3>2. {t('facilities.category')}</h3>
                <Tooltip title={t('facilities.categoryExplanation')} placement='top'>
                  <Info fontSize='inherit' />
                </Tooltip>
              </ToolTipWrapper>

              {categories && <PillSelector name='categories' pills={categories} type='checkbox' />}

              <Field type='hidden' name='suggestedCategory' value='None' />
            </FormGroup>

            <FormGroup width='narrow'>
              <SwitchInput
                label={t('facilities.monumentStatus')}
                tooltip={t('facilities.monumentStatusExplanation')}
                handleChange={(e) => setFieldValue('isMonument', e.target.checked)}
              />
            </FormGroup>

            <FormGroup width='narrow'>
              <h3>3. {t('facilities.purposeAndUse')}</h3>
              <Field
                name='description'
                as='textarea'
                placeholder={t('facilities.purposeAndUsePlaceHolder')}
                disabled={messageSent}
              />
            </FormGroup>

            <FormGroup width='wide'>
              <h3>4. {t('facilities.location')}</h3>
              <LocationWrapper>
                <LocationInputWrapper>
                  <AnimatedInput
                    label={t('facilities.postalCode')}
                    name='location.postCode'
                    handleChange={handleAddressChange}
                  />
                  <AnimatedInput
                    label={t('facilities.buildingNumber')}
                    name='location.buildingNumber'
                    handleChange={handleAddressChange}
                  />
                  <AnimatedInput
                    label={t('facilities.extension')}
                    name='location.extension'
                    handleChange={handleAddressChange}
                  />
                </LocationInputWrapper>
                <LocationImageWrapper>
                  <LocationImage id='map' />
                </LocationImageWrapper>
                {location && (
                  <LocationValueWrapper>
                    <AddresWrapper>
                      <span>{`${location.street} ${location.buildingNumber} ${location.extension}`}</span>
                      <span>{`${location.postCode} ${location.city}`}</span>
                      <span>{location.province}</span>
                    </AddresWrapper>
                    <CoordinatesWrapper>
                      <span>{location.latFormatted}</span>
                      <span>{location.lonFormatted}</span>
                    </CoordinatesWrapper>
                    <TownshipWrapper>
                      <span>
                        {t('facilities.municipality')}: {location.municipality}
                      </span>
                    </TownshipWrapper>
                  </LocationValueWrapper>
                )}
              </LocationWrapper>
            </FormGroup>

            <FormGroup width='narrow'>
              <DetailsWrapper>
                <FormHeadingThree>5. {t('facilities.technicalCharacteristics')}</FormHeadingThree>
                <DetailInfoWrapper>
                  <span>{t('facilities.constructionYear')}:</span>
                  <Field
                    name='details.year'
                    type='text'
                    placeholder={t('facilities.year')}
                    className='inlineField'
                  />
                </DetailInfoWrapper>
                <DetailInfoWrapper>
                  <span>{t('facilities.grossArea')}:</span>
                  <Field
                    name='details.grossArea'
                    type='text'
                    placeholder='m2'
                    className='inlineField'
                  />
                </DetailInfoWrapper>
                <AnimatedInput
                  label={t('facilities.formFactor')}
                  name='details.formFactor'
                  handleChange={handleChange}
                />
                <AnimatedInput
                  label={t('facilities.usefulArea')}
                  name='details.usefulArea'
                  handleChange={handleChange}
                />
                <AnimatedInput
                  label={t('facilities.rentableArea')}
                  name='details.rentArea'
                  handleChange={handleChange}
                />
              </DetailsWrapper>
            </FormGroup>

            <FormGroup width='wide'>
              {energyLabels && (
                <PillSelector
                  name='energyLabel'
                  label={t('facilities.efficiencyLabel')}
                  pills={energyLabels}
                  type='radio'
                />
              )}
            </FormGroup>

            <FormGroup width='narrow'>
              <SwitchInput
                label={t('facilities.nta8800Approved')}
                tooltip={t('facilities.nta8800ApprovedExplanation')}
                handleChange={(e) => setFieldValue('isNtaApproved', e.target.checked)}
              />
            </FormGroup>

            <FormFooter>
              <SubmitButton
                completed={messageSent}
                completedText={t('facilities.facilityAdded')}
                submitText={t('common.done')}
                loading={isSubmitting}
                disabled={!isValid}
                type='primary'
              />
            </FormFooter>
          </StyledForm>
        )
      }}
    </Formik>
  )
}

const FormHeadingThree = styled.h3`
  margin-bottom: 20px;
`

const LocationWrapper = styled.div`
  display: flex;
  flex-flow: row;
`
const LocationInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  min-width: 165px;
`

const LocationValueWrapper = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 100%;
`

const LocationImageWrapper = styled.div`
  width: 190px;
  height: 190px;
  margin: 0 20px;
`

const LocationImage = styled.div`
  width: 190px;
  height: 175px;

  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib {
    display: none !important;
  }
`
export const CaptionLeft = styled.p`
  font-size: 12px;
  margin-inline: 15px;
`
export const CaptionLeftNegative = styled(CaptionLeft)`
  color: ${({ theme }) => theme.colors.inputs.errorText};
  margin-block: 9px 0px;
  margin-inline: 15px;
`

const AddresWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 500;
  font-stretch: condensed;
  color: ${({ theme }) => theme.colors.body.primary};
`

const CoordinatesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
`

const TownshipWrapper = styled.div``

const DetailsWrapper = styled.div`
  display: flex;
  flex-flow: column;
`

const DetailInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  span {
    flex: 1 0 auto;
  }

  input {
    flex: 0 1 auto;
  }
`

const ToolTipWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`
