import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'

import { Tooltip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import styled from 'styled-components'

import { useAppData } from 'context'
import { useFormatDetailList } from 'hooks'
import { getFacilityImg } from 'services'
import { cardContainer, cardDetailItem, cardDetailList, cardImageWrapper } from 'styles'
import { Facility } from 'types'

interface Props {
  className?: string
  facility: Facility
  number?: number
  total?: number
  tooltip?: string
}

interface WrapperProps {
  tooltip: boolean
  wrapper: (children: React.ReactElement) => React.ReactElement
  children: React.ReactElement
}

const TooltipWrapper: React.FC<WrapperProps> = ({ tooltip, wrapper, children }) =>
  tooltip ? wrapper(children) : children

export const FacilityCard: React.FC<Props> = ({ className, facility, number, total, tooltip }) => {
  const { tool, view } = useAppData()
  const navigate = useNavigate()
  const details = useFormatDetailList(facility, tool)

  const { data: image } = useQuery(
    ['facilityImg', facility.id],
    () => getFacilityImg(facility.id),
    { enabled: !!facility.img, staleTime: 1000 * 60 * 60 },
  )

  return (
    <>
      <TooltipWrapper
        tooltip={!!tooltip}
        wrapper={(children) => (
          <Tooltip title={tooltip} placement='top'>
            {children}
          </Tooltip>
        )}
      >
        <CardContainer
          className={`${className} ${view}`}
          onClick={() => navigate(`/${tool}/facilities/${facility.id}`)}
          number={number}
          total={total}
        >
          {!!facility.img && (
            <ImageWrapper>
              {image ? (
                <img src={image} alt={facility.name} />
              ) : (
                <Skeleton width={90} height={90} />
              )}
            </ImageWrapper>
          )}
          <Content>
            <h3>{facility.name}</h3>
            <DetailList>
              {details &&
                details
                  .filter((detail) => detail.value !== '' && detail.value !== 'None')
                  .map(({ value, className }, i) => (
                    <DetailItem className={className} key={i}>
                      {value}
                    </DetailItem>
                  ))}
            </DetailList>
          </Content>
        </CardContainer>
      </TooltipWrapper>
    </>
  )
}

interface CardProps {
  number?: number
  total?: number
}

const CardContainer = styled.div<CardProps>`
  ${cardContainer}

  background-color: ${({ theme }) => theme.colors.card.backgroundAlt};
`

const ImageWrapper = styled.div`
  ${cardImageWrapper}
`

const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
`

const DetailList = styled.ul`
  ${cardDetailList}
`

const DetailItem = styled.li`
  ${cardDetailItem}
`
