import React from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'
import styled, { useTheme } from 'styled-components'
import {
  Bar,
  VictoryArea,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryPolarAxis,
  VictoryStack,
  VictoryTheme,
} from 'victory'

import { getPolicyTemplate, PriorityLevels } from 'services'
import { Policy } from 'types'

interface Props {
  policyId: string
}

const goalsValues = { safety: 0, health: 0, usability: 0, energy: 0, environment: 0, society: 0 }
const subgoalsValues = {
  energy: {
    energyEfficiency: 0,
    emission: 0,
  },
  environment: {
    culturalHistoricalValue: 0,
    imageAspects: 0,
    knowledgeLoss: 0,
  },
  health: {
    comfortAndCosiness: 0,
    wellbeing: 0,
  },
  safety: {
    limitCasualties: 0,
    limitDamageThirdParties: 0,
  },
  society: {
    sustainability: 0,
    limitDamageSurrounding: 0,
  },
  usability: {
    limitDamageUserProcess: 0,
    limitDamageOwnerFinancially: 0,
    safetyInternalExternal: 0,
  },
} as { [key: string]: { [key: string]: number } }

const formatGoalValues = (data: { [key: string]: number }) => {
  return Object.keys(data).map((key) => {
    return { x: key, y: data[key] }
  })
}

export const PolicyDetailsTab: React.FC<Props> = ({ policyId }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { data: policyTemplate, isLoading } = useQuery(['policyTemplate', policyId], () =>
    getPolicyTemplate(policyId),
  )

  if (!isLoading) {
    const goalsTotal = Object.values(policyTemplate.goals).reduce((acc, cur) => {
      return acc + Object.values(cur).reduce((acc, cur) => acc + cur, 0)
    }, 0)

    Object.keys(policyTemplate.goals).forEach((goalName: keyof Policy['goals']) => {
      const goal = policyTemplate.goals[goalName]
      const goalTotal = Object.values(goal).reduce((acc, value) => acc + value, 0)

      goalsValues[goalName] = +((goalTotal / goalsTotal) * 100).toFixed(2)
    })

    Object.keys(policyTemplate.subgoals).forEach((g) => {
      const goalName = g as keyof Policy['goals']
      const goals = Object.keys(policyTemplate.subgoals[goalName])
      const goalTotal = Object.values(policyTemplate.subgoals[goalName]).length * 4.5

      goals.map((s) => {
        const subgoalName = s as keyof Policy['subgoals'][typeof goalName]
        const subgoals = Object.keys(policyTemplate.subgoals[goalName][subgoalName])
        const subgoalTotal =
          Object.values(policyTemplate.subgoals[goalName][subgoalName]).reduce<number>(
            (acc: number, value: number) => acc + value,
            0,
          ) / subgoals.length

        subgoalsValues[goalName][subgoalName] = +((subgoalTotal / goalTotal) * 100).toFixed(2)
      })
    })
  }

  const barColors = ['mango', 'dawn', 'jungle']
  const priorityLevel = PriorityLevels.find((level) => level.value === policyTemplate?.priority)

  return (
    <Container>
      <Content>
        <ChartWrapper>
          <h3>{t('policies.goalsInteraction')}</h3>
          <VictoryChart
            polar
            theme={VictoryTheme.material}
            domain={{ y: [0, 50] }}
            startAngle={30}
            endAngle={390}
          >
            <VictoryPolarAxis
              style={{
                axis: { strokeWidth: 0 },
                axisLabel: { padding: 30 },
                grid: { stroke: theme.colors.graph.grid, strokeWidth: 0.25, opacity: 0.5 },
                ticks: { strokeWidth: 0 },
              }}
              tickLabelComponent={
                <VictoryLabel
                  labelPlacement='perpendicular'
                  style={{
                    fontSize: 8,
                    fill: theme.colors.body.text,
                    fontFamily: '"Syne", sans-serif',
                  }}
                />
              }
            />
            <VictoryArea
              data={formatGoalValues(goalsValues)}
              style={{
                data: {
                  fill: `rgba(${theme.colors.graph.background}, 0.2)`,
                  stroke: theme.colors.graph.text,
                  strokeWidth: 1,
                },
              }}
            />
          </VictoryChart>
        </ChartWrapper>
      </Content>
      <Sidebar>
        {priorityLevel && (
          <DetailsWrapper>
            <h3>Involved</h3>
            {Object.keys(priorityLevel.details).map((key, i) => (
              <Detail key={i}>
                <span>{priorityLevel.details[key as keyof typeof priorityLevel.details]}</span>
                <span>{t(`policies.priority.details.${key}`)}</span>
              </Detail>
            ))}
          </DetailsWrapper>
        )}
        <GoalsWrapper>
          <h3>{t('policies.goals.goals')}</h3>
          {Object.keys(goalsValues).map((key, i) => (
            <GoalBarGraph key={i}>
              <span>{t(`policies.goals.${key}`)}</span>
              <VictoryStack width={300} height={20} padding={0} horizontal>
                {Object.keys(subgoalsValues[key]).map((subkey, i) => (
                  <VictoryBar
                    key={i}
                    data={[{ x: subkey, y: subgoalsValues[key][subkey] }]}
                    dataComponent={<Bar className={barColors[i]} />}
                    barWidth={40}
                  />
                ))}
              </VictoryStack>
              <BarLabels>
                {Object.keys(subgoalsValues[key]).map((subkey, i) => {
                  const width = subgoalsValues[key][subkey]

                  return (
                    <BarLabel key={i} width={width} className={barColors[i]}>
                      {t(`policies.subgoals.${subkey}`)}
                    </BarLabel>
                  )
                })}
              </BarLabels>
            </GoalBarGraph>
          ))}
        </GoalsWrapper>
      </Sidebar>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row nowrap;
  align-items: flex-start;
  row-gap: 50px; ;
`
const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
`
const Sidebar = styled.div`
  width: 425px;
`
const ChartWrapper = styled.div`
  width: 75%;

  h3 {
    margin-bottom: 25px;
  }
`
const DetailsWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 60px;

  h3 {
    flex: 1 0 100%;
  }
`
const Detail = styled.div`
  display: flex;
  position: relative;
  flex: 0 0 50%;
  flex-flow: column nowrap;

  span {
    &:first-child {
      margin-top: 25px;
      font-size: 24px;
    }

    &:last-child {
      font-size: 12px;
    }
  }
`
const GoalsWrapper = styled.div`
  h3 {
    margin-bottom: 25px;
  }
`
const GoalBarGraph = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: flex-start;
  height: 20px;
  margin-bottom: 50px;

  span {
    flex: 1 1 125px;
  }

  .VictoryContainer {
    flex: 1 1 300px;
    height: 20px;

    path {
      &.mango {
        fill: ${({ theme }) => theme.colors.mango.hex} !important;
        stroke: ${({ theme }) => theme.colors.mango.hex} !important;
      }

      &.dawn {
        fill: ${({ theme }) => theme.colors.dawn.hex} !important;
        stroke: ${({ theme }) => theme.colors.dawn.hex} !important;
      }

      &.jungle {
        fill: ${({ theme }) => theme.colors.jungle.hex} !important;
        stroke: ${({ theme }) => theme.colors.jungle.hex} !important;
      }
    }
  }
`
const BarLabels = styled.div`
  display: flex;
  position: absolute;
  top: 25px;
  left: 125px;
  width: 300px;
`
const BarLabel = styled.span<{ width: number }>`
  width: ${({ width }) => width}%;
  padding-right: 8px;
  font-size: 12px;
  font-stretch: condensed;
`
