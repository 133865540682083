import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import 'react-loading-skeleton/dist/skeleton.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import RadioCanadaCondensedLightWoff from './fonts/radiocanada/condensed/light.woff'
import RadioCanadaCondensedLightWoff2 from './fonts/radiocanada/condensed/light.woff2'
import RadioCanadaCondensedMediumWoff from './fonts/radiocanada/condensed/medium.woff'
import RadioCanadaCondensedMediumWoff2 from './fonts/radiocanada/condensed/medium.woff2'
import RadioCanadaLightWoff from './fonts/radiocanada/light.woff'
import RadioCanadaLightWoff2 from './fonts/radiocanada/light.woff2'
import RadioCanadaMediumWoff from './fonts/radiocanada/medium.woff'
import RadioCanadaMediumWoff2 from './fonts/radiocanada/medium.woff2'
import SyneMediumWoff from './fonts/syne/medium.woff'
import SyneMediumWoff2 from './fonts/syne/medium.woff2'

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: 'Radio Canada';
    font-weight: 300;
    font-display: block;
    src: url(${RadioCanadaLightWoff}) format('woff'),
         url(${RadioCanadaLightWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Radio Canada';
    font-weight: 500;
    font-display: block;
    src: url(${RadioCanadaMediumWoff}) format('woff'),
         url(${RadioCanadaMediumWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Radio Canada';
    font-weight: 300;
    font-display: block;
    font-stretch: condensed;
    src: url(${RadioCanadaCondensedLightWoff}) format('woff'),
         url(${RadioCanadaCondensedLightWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Radio Canada';
    font-weight: 500;
    font-display: block;
    font-stretch: condensed;
    src: url(${RadioCanadaCondensedMediumWoff}) format('woff'),
         url(${RadioCanadaCondensedMediumWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Syne';
    font-weight: 500;
    font-display: block;
    src: url(${SyneMediumWoff}) format('woff'),
         url(${SyneMediumWoff2}) format('woff2');
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

    html, body {
      min-height: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      background: ${({ theme }) => theme.colors.body.bodyBackground};
      color: ${({ theme }) => theme.colors.body.text};
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-size: 18px;
      font-weight: 300;
      transition: background-color 200ms ease;
  }

  #root {
    min-height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  button {
    all: unset;
  }

  div {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
    font-weight: 500;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.66rem;
  }

  h3 {
    font-size: 1.33rem;
    margin: 0;
  }

  p {
    margin: 10px 0;
  }

  a {
    color: ${({ theme }) => theme.colors.body.primary};
    font-weight: 500;
    font-stretch: condensed;
    text-decoration: none;
    cursor: pointer;
  }

  .react-loading-skeleton {
    --base-color: ${({ theme }) => theme.colors.skeleton.base};
    --highlight-color: ${({ theme }) => theme.colors.skeleton.highlight};
  }
`
