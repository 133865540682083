import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Add,
  Apps,
  ArrowBackIos,
  Close,
  FormatListBulleted,
  MoreHoriz,
  North,
  South,
} from '@mui/icons-material'
import styled from 'styled-components'

type Icons = 'add' | 'close' | 'dots' | 'leftArrow' | 'north' | 'south' | 'list' | 'grid'

interface Props {
  text?: string
  link?: string
  icon?: Icons
  type?: 'button' | 'submit' | 'reset'

  onClick?: () => void | Promise<void>
}

export const Button: React.FC<Props> = ({ text, link, icon, type = 'button', onClick }) => {
  const navigate = useNavigate()

  const goToLink = () => {
    if (link !== '') {
      navigate(link, { replace: false })
    }
  }

  const getIcon = (name: Icons) => {
    switch (name) {
      case 'add':
        return <Add />
      case 'close':
        return <Close />
      case 'dots':
        return <MoreHoriz />
      case 'grid':
        return <Apps />
      case 'list':
        return <FormatListBulleted />
      case 'north':
        return <North />
      case 'leftArrow':
        return <ArrowBackIos />
      case 'south':
        return <South />
    }
  }

  return (
    <Container
      className={icon ? 'icon' : ''}
      onClick={link ? goToLink : onClick}
      icon={icon}
      type={type}
    >
      {icon && getIcon(icon)}

      {text}
    </Container>
  )
}

const Container = styled.button<Props>`
  display: flex;
  padding: 14px 56px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: background-color 250ms ease;
  background-color: ${({ theme }) => theme.colors.body.primary};
  color: ${({ theme }) => theme.colors.button.text};

  &.icon {
    min-width: 60px;
    height: 60px;
    padding: 0;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.body.text};
    border: none;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.body.icon};
      }
    }

    &:hover {
      background-color: transparent;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.body.primaryLight};
    cursor: pointer;
    transition: background-color 0.5s ease;
  }
`
