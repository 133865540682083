import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Info } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Formik, FormikHelpers } from 'formik'
import i18n from 'i18n'
import styled from 'styled-components'
import * as Yup from 'yup'

import {
  BreadcrumbNav,
  ContactPersonForm,
  FormFooter,
  FormGroup,
  InputCard,
  MaintenanceRadio,
  Modal,
  OpenHoursInput,
  PillSelector,
  SelectInput,
  StyledForm,
  SubmitButton,
  SwitchInput,
} from 'components'
import { useClusterFormState, type OpenHour } from 'hooks'
import {
  getClusters,
  getContacts,
  getFacility,
  getFacilityImg,
  getPolicyImg,
  newCluster,
  updateCluster,
} from 'services'
import { Cluster, ClusterDiscipline, MaintenanceLevel } from 'types'

const initialValues = (cluster: Cluster): Cluster => ({
  contacts: cluster?.contacts || [],
  disciplines: cluster?.disciplines || [],
  hasOpenHours: cluster?.hasOpenHours || false,
  inspections: cluster?.inspections || false,
  malfunctions: cluster?.malfunctions || false,
  maintenanceLevel: cluster?.maintenanceLevel || MaintenanceLevel.NONE,
  name: cluster?.name || '',
  openHours: cluster?.openHours || [],
  preventiveMaintenance: cluster?.preventiveMaintenance || false,
})

const maintenanceLevels = [
  {
    label: i18n.t('clusters.maintenanceLevels.basic'),
    value: MaintenanceLevel.BASIC,
  },
  {
    label: i18n.t('clusters.maintenanceLevels.minimal'),
    value: MaintenanceLevel.MINIMAL,
  },
  {
    label: i18n.t('clusters.maintenanceLevels.plus'),
    value: MaintenanceLevel.PLUS,
  },
]

i18n.t('clusters.maintenanceLevels.basic')
const disciplines = [
  {
    label: i18n.t('clusters.disciplines.residential'),
    value: ClusterDiscipline.RESIDENTIAL,
  },
  {
    label: i18n.t('clusters.disciplines.assembly'),
    value: ClusterDiscipline.ASSEMBLY,
  },
  {
    label: i18n.t('clusters.disciplines.prison'),
    value: ClusterDiscipline.PRISON,
  },
  {
    label: i18n.t('clusters.disciplines.healthcare'),
    value: ClusterDiscipline.HEALTHCARE,
  },
  {
    label: i18n.t('clusters.disciplines.office'),
    value: ClusterDiscipline.OFFICE,
  },
  {
    label: i18n.t('clusters.disciplines.lodging'),
    value: ClusterDiscipline.LODGING,
  },
  {
    label: i18n.t('clusters.disciplines.education'),
    value: ClusterDiscipline.EDUCATION,
  },
  {
    label: i18n.t('clusters.disciplines.sports'),
    value: ClusterDiscipline.SPORTS,
  },
  {
    label: i18n.t('clusters.disciplines.store'),
    value: ClusterDiscipline.STORE,
  },
  {
    label: i18n.t('clusters.disciplines.additional'),
    value: ClusterDiscipline.ADDITIONAL,
  },
  {
    label: i18n.t('clusters.disciplines.other'),
    value: ClusterDiscipline.OTHER,
  },
]

const ClusterSchema = Yup.object().shape({
  name: Yup.string().required(),
  hasOpenHours: Yup.boolean().required(),
  openHours: Yup.array()
    .of(
      Yup.object().shape({
        day: Yup.string().required(),
        from: Yup.string().when('isOpen', {
          is: true,
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),
        to: Yup.string().when('isOpen', {
          is: true,
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),
        isOpen: Yup.boolean().required(),
      }),
    )
    .nullable(),
  maintenanceLevel: Yup.string().required(),
  preventiveMaintenance: Yup.boolean().required(),
  inspections: Yup.boolean().required(),
  malfunctions: Yup.boolean().required(),
  disciplines: Yup.array().min(1).required(),
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        isOwner: Yup.boolean().required(),
      }),
    )
    .nullable(),
})

export const ClusterForm: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { facilityId, clusterId } = useParams()
  const [state, dispatch] = useClusterFormState()
  const { data: contacts } = useQuery(['contacts', facilityId], () => getContacts(facilityId))
  const { data: facility } = useQuery(['facility', facilityId], () => getFacility(facilityId))
  const { data: clusters, isLoading } = useQuery(
    ['clusters', facilityId],
    () => getClusters(facilityId),
    {
      enabled: !!clusterId,
    },
  )

  const cluster = clusters ? clusters.find((c) => c.id === clusterId) : null

  const { data: facilityImage } = useQuery(
    ['facilityImage', facilityId],
    () => getFacilityImg(facilityId),
    {
      enabled: !!facility?.img,
    },
  )

  const { data: policyImage } = useQuery(
    ['policyImage', cluster?.id],
    () => getPolicyImg(cluster?.id),
    {
      enabled: !!cluster?.policyId,
    },
  )

  const newClusterMutation = useMutation((values: Cluster) => newCluster(facilityId, values), {
    onSuccess: (clusterId) => {
      navigate(`/map/facilities/${facilityId}/clusters/${clusterId}`)
    },
  })

  const updateClusterMutation = useMutation(
    (values: Cluster) => updateCluster(facilityId, cluster.id, values),
    {
      onSuccess: (clusterId) => {
        navigate(`/map/facilities/${facilityId}/clusters/${clusterId}`)
      },
    },
  )

  const clusterMutation = cluster ? updateClusterMutation : newClusterMutation

  const handleSubmit = (values: Cluster, { setSubmitting }: FormikHelpers<Cluster>) => {
    clusterMutation.mutate(values)
    setSubmitting(false)
    queryClient.invalidateQueries(['clusters', facilityId])
  }

  useEffect(() => {
    const options = contacts?.map((contact) => ({
      label: `${contact.firstName} ${contact.lastName}`,
      value: { id: contact.id, isOwner: false },
    }))

    dispatch({ type: 'setContactOptions', payload: options })
  }, [contacts, dispatch])

  return (
    <>
      {facility && (
        <BreadcrumbNav
          crumbs={[
            {
              title: facility.name,
              image: facilityImage,
              link: `/map/facilities/${facilityId}`,
            },
          ]}
          size='small'
        />
      )}

      {((clusterId && !isLoading) || !clusterId) && (
        <Formik
          initialValues={initialValues(cluster)}
          validationSchema={ClusterSchema}
          onSubmit={handleSubmit}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {({ values, isSubmitting, isValid, setFieldValue }) => {
            const handleSpecificWorkingHours = (checked: boolean) => {
              dispatch({ type: 'setSpecificWorkingHours', payload: checked })
              dispatch({ type: 'setWorkingDays', payload: checked ? 'short' : 'long' })
              setFieldValue('hasOpenHours', checked)

              if (checked) {
                setFieldValue('openHours', state.openHours)
              }
            }

            const handleSpecificDaily = (checked: boolean) => {
              dispatch({ type: 'setWorkingDays', payload: checked ? 'long' : 'short' })

              if (checked) {
                setFieldValue('openHours', state.openHours)
              }
            }

            const formatOpenHours = async (value: OpenHour) => {
              let openHours
              if (state.workingDays.length === 2) {
                openHours = state.openHours.map((openHour) => {
                  if (
                    value?.day === 'Mon-Fri' &&
                    ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(openHour?.day)
                  ) {
                    return {
                      ...openHour,
                      isOpen: value.isOpen,
                      from: value.from || '',
                      to: value.to || '',
                    }
                  } else if (
                    value?.day === 'Sat-Sun' &&
                    ['Saturday', 'Sunday'].includes(openHour?.day)
                  ) {
                    return {
                      ...openHour,
                      isOpen: value.isOpen,
                      from: value.from || '',
                      to: value.to || '',
                    }
                  } else {
                    return openHour
                  }
                })
              } else {
                openHours = state.openHours.map((item) => (item.day === value.day ? value : item))
              }

              setFieldValue('openHours', openHours)
              dispatch({ type: 'setOpenHours', payload: openHours })
            }

            return (
              <StyledForm>
                <h1>
                  {facility?.name}: {cluster ? values.name : 'new cluster'}
                </h1>
                <FormGroup width='narrow'>
                  <h3>1. {t('clusters.appearance')}</h3>
                  <InputCard
                    avatar={policyImage || 'empty'}
                    image={facilityImage}
                    label={t('clusters.addName')}
                    name='name'
                  />
                </FormGroup>

                <FormGroup width='narrow'>
                  <h3>2. {t('contacts.contactPerson')}</h3>
                  <SelectInput
                    name='contacts'
                    options={state.contactOptions}
                    placeholder={
                      state.contactOptions?.length > 0
                        ? t('contacts.contactPersonSelect')
                        : t('contacts.contactPersonAdd')
                    }
                  />
                  <InlineButton
                    onClick={() => dispatch({ type: 'setModalState', payload: 'open' })}
                  >
                    {t('contacts.addperson')}
                  </InlineButton>
                  {values.contacts.length > 0 && (
                    <SwitchInput
                      label='This person is the owner'
                      checked={values.contacts[0].isOwner}
                      handleChange={(e) =>
                        setFieldValue('contacts', [
                          { ...values.contacts[0], isOwner: e.target.checked },
                        ])
                      }
                    />
                  )}
                </FormGroup>
                <FormGroup width='narrow'>
                  <ToolTipWrapper>
                    <h3>3. {t('clusters.workingHours')}</h3>
                    <Tooltip title={t('clusters.workingHoursExplanation')} placement='top'>
                      <Info fontSize='inherit' />
                    </Tooltip>
                  </ToolTipWrapper>
                  <SwitchInput
                    label={t('clusters.specificWorkingHours')}
                    handleChange={(e) => handleSpecificWorkingHours(e.target.checked)}
                  />
                  {!state.specificWorkingHours && <p>{t('clusters.alwaysAccesable')}</p>}

                  {state.specificWorkingHours && (
                    <>
                      <SwitchInput
                        label={t('clusters.specificDaily')}
                        handleChange={(e) => handleSpecificDaily(e.target.checked)}
                      />

                      {state.workingDays.map((day) => (
                        <OpenHoursInput
                          label={day.label}
                          value={day.value}
                          key={day.value}
                          handleChange={formatOpenHours}
                        />
                      ))}
                    </>
                  )}
                </FormGroup>

                <FormGroup width='narrow'>
                  <ToolTipWrapper>
                    <h3>4. {t('clusters.maintenanceLevel')}</h3>
                    <Tooltip title={t('clusters.maintenanceLevelExplanation')} placement='top'>
                      <Info fontSize='inherit' />
                    </Tooltip>
                  </ToolTipWrapper>
                  <MaintenanceRadio
                    value={cluster?.maintenanceLevel}
                    options={maintenanceLevels}
                    handleChange={(value) => setFieldValue('maintenanceLevel', value)}
                  />
                </FormGroup>

                <FormGroup width='narrow'>
                  <h3>5. {t('clusters.attentionLevel')}</h3>
                  <SwitchInput
                    label={t('clusters.preventiveMaintenance')}
                    handleChange={(e) => setFieldValue('preventiveMaintenance', e.target.checked)}
                  />
                  <SwitchInput
                    label={t('clusters.inspections')}
                    handleChange={(e) => setFieldValue('inspections', e.target.checked)}
                  />
                  <SwitchInput
                    label={t('clusters.malfunctions')}
                    handleChange={(e) => setFieldValue('malfunctions', e.target.checked)}
                  />
                </FormGroup>

                <FormGroup width='wide'>
                  <h3>6. {t('clusters.disciplinesHeader')}</h3>
                  <p>{t('clusters.selectDisciplines')}</p>
                  {disciplines && (
                    <PillSelector name='disciplines' pills={disciplines} type='checkbox' />
                  )}
                </FormGroup>

                <FormFooter>
                  <SubmitButton
                    completed={false}
                    completedText={t('clusters.added')}
                    submitText={t('common.done')}
                    loading={isSubmitting}
                    disabled={!isValid}
                    type='secondary'
                  />
                  <SubmitButton
                    completed={false}
                    completedText={t('clusters.added')}
                    submitText={t('clusters.applyPolicy')}
                    loading={isSubmitting}
                    disabled={!isValid}
                    type='primary'
                    submit={false}
                  />
                </FormFooter>
              </StyledForm>
            )
          }}
        </Formik>
      )}

      <Modal state={state.contactModalState}>
        <ContactPersonForm
          facilityId={facilityId}
          closeForm={() => dispatch({ type: 'setModalState', payload: 'closed' })}
        />
      </Modal>
    </>
  )
}

const InlineButton = styled.a`
  position: relative;
  margin: 20px 0 20px 0;
  color: ${({ theme }) => theme.colors.mango.hex};
  font-family: 'Radio Canada';
  font-weight: 500;
  font-stretch: condensed;
  text-align: right;
  cursor: pointer;
`

const ToolTipWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`
