import { Facility } from 'types'

import http from './http-common'

export const getFacilities = (): Promise<Facility[]> =>
  http.get<Facility[]>('/facility/all').then((response) => response.data)

export const getFacility = (facilityId: string): Promise<Facility> =>
  http.get('/facility', { params: { facilityId: facilityId } }).then((response) => response.data)

export const getFacilityImg = (facilityId: string): Promise<string> =>
  http
    .get('/facility/img', { responseType: 'blob', params: { facilityId } })
    .then((response) => URL.createObjectURL(response.data))

export const newFacility = (data: Facility) =>
  http.post('/facility', data).then((response) => response.data)

export const newFacilityImg = (facilityId: string, file: File) =>
  http
    .post(
      '/facility/img/upload',
      { file },
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { facilityId },
      },
    )
    .then((response) => response.data)

export const updateFacility = (facilityId: string, data: Facility) =>
  http.put('/facility', data, { params: { facilityId } }).then((response) => response.data)

export const deleteFacility = (facilityId: string) =>
  http.delete('/facility', { params: { facilityId } }).then((response) => response.data)
