import React, { useState } from 'react'

import styled from 'styled-components'

interface TabBarProps {
  tabs: string[]
  active?: number
  disabled?: number[]
  children: React.ReactNode
}

interface TabContainerProps {
  activeTab?: string
  tab: string
  children: React.ReactNode
}

export const TabBar: React.FC<TabBarProps> = ({ tabs, active, disabled, children }) => {
  const [activeTab, setActiveTab] = useState(tabs[active] || tabs[0])

  return (
    <>
      <TabList>
        {tabs &&
          tabs.map((tab, i) => (
            <Tab
              key={i}
              className={`
                ${activeTab === tab ? 'active' : ''} 
                ${disabled?.includes(i) ? 'disabled' : ''}
              `}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </Tab>
          ))}
      </TabList>

      {React.Children.map(children, (child: React.ReactElement) =>
        React.cloneElement(child, { activeTab }),
      )}
    </>
  )
}

export const TabView: React.FC<TabContainerProps> = ({ activeTab, tab, children }) => (
  <FullWidthContainer id={tab} className={activeTab === tab ? 'active tabview' : 'tabview'}>
    <Content>
      {React.Children.map(children, (child: React.ReactElement) =>
        React.cloneElement(child, { isActive: activeTab === tab }),
      )}
    </Content>
  </FullWidthContainer>
)

const TabList = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  margin: 30px 0 20px 0;
  padding: 0;
  list-style-type: none;
`

const Tab = styled.li`
  margin-right: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  font-stretch: condensed;
  text-transform: capitalize;
  transition: all 250ms;
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) => theme.colors.body.backgroundAlt};
  }

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.body.background};
  }
`

const FullWidthContainer = styled.div`
  display: none;
  position: relative;
  flex-grow: 1;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  background-color: ${({ theme }) => theme.colors.tabbar.background};

  &.active {
    display: block;
  }
`

const Content = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 60px 60px;
`
