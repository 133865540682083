import React from 'react'

import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

export const ToolbarItem: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

const Container = styled.header`
  position: relative;
`
