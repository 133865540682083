import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { Field, FieldProps } from 'formik'
import styled from 'styled-components'

interface Props {
  name: string
  options: Option[]
  placeholder: string
  selected?: string
  flatten?: boolean
}

interface CustomSelectProps extends FieldProps {
  options: Option[]
  className?: string
  placeholder?: string
  flatten?: boolean
  selected?: string
  setValue?: (value: string) => void
}

interface ContactOptionValue {
  id: string
  isOwner?: boolean
}

interface Option {
  label: string
  value: ContactOptionValue | string
}

export const SelectInput: React.FC<Props> = ({ name, options, placeholder, selected, flatten }) => {
  return (
    <Field
      className='customSelect'
      name={name}
      options={options}
      component={CustomSelect}
      placeholder={placeholder}
      selected={selected}
      flatten={flatten}
    />
  )
}

const CustomSelect = ({
  className,
  flatten,
  field,
  form,
  options,
  placeholder,
  selected,
}: CustomSelectProps) => {
  const { t } = useTranslation()
  const getValue = () => {
    if (options && selected) {
      const selectedOption = { id: selected }
      const theOption = options.find((option) => {
        if (typeof option.value === 'object') {
          return option.value.id === selectedOption.id
        } else {
          return option.value === selected
        }
      })

      return theOption
    } else if (options && field.value) {
      return options.find((option) => option.value === field.value[0])
    } else {
      return '' as string
    }
  }

  const setValue = (option: Option) => {
    if (flatten && typeof option?.value === 'object') {
      form.setFieldValue(field.name, [option.value.id])
    } else {
      form.setFieldValue(field.name, [option.value])
    }
  }

  return (
    <StyledSelect
      className={className}
      classNamePrefix={className}
      name={field.name}
      value={getValue()}
      onChange={setValue}
      placeholder={placeholder}
      options={options}
      noOptionsMessage={() => t('common.noOptions')}
    />
  )
}

const StyledSelect = styled(Select)`
  position: relative;

  .customSelect__control {
    padding: 6px;
    border-radius: 0;
  }

  .customSelect__menu {
    color: ${({ theme }) => theme.colors.inputs.text};

    .customSelect__option--is-focused {
      background-color: ${({ theme }) => theme.colors.mango.hex};
    }
  }

  .customSelect__indicator svg path {
    fill: ${({ theme }) => theme.colors.body.backgroundAlt};
  }

  .customSelect__indicator-separator {
    display: none;
  }
`
