import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

interface Props {
  state: 'open' | 'closed'
  children?: React.ReactNode
}

export const Modal: React.FC<Props> = ({ state, children }) => {
  const [classNames, setClassNames] = useState('')

  useEffect(() => {
    switch (state) {
      case 'open':
        setClassNames('modal')

        setTimeout(() => {
          setClassNames('modal show')
        }, 100)
        break
      case 'closed':
        setClassNames('modal closed')

        setTimeout(() => {
          setClassNames('')
        }, 250)
        break
    }
  }, [state])

  return (
    <ModalContainer className={classNames}>
      <ModalBody>{children}</ModalBody>
    </ModalContainer>
  )
}

const ModalContainer = styled.div`
  display: none;
  position: fixed;
  top: 50vh;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: auto;
  background-color: ${({ theme }) => theme.colors.modal.background};
  backdrop-filter: blur(5px);
  transform: translateY(-50%);
  transition: 250ms opacity ease, 250ms transform ease;
  z-index: 1000;

  &.modal {
    display: flex;
    opacity: 0;
    transform: translateY(calc(-50% + 20px));
  }

  &.show {
    opacity: 1;
    transform: translateY(-50%);
  }

  &.closed {
    transform: translateY(calc(-50% - 20px));
  }
`

const ModalBody = styled.div`
  min-width: 450px;
  padding: 10px 50px;
  border-radius: 4px;
`
