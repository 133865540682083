import { Action } from 'types'

export const actionsDummy: Action[] = [
  {
    id: '1',
    title: 'The Title for an action ticket targeting reduction of electricity consumption',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non quam nec neque volutpat faucibus. In feugiat imperdiet mattis. Aliquam eu ex quis sapien varius mollis in vel neque. Proin vehicula posuere elit, et lobortis elit accumsan non. Integer imperdiet aliquam dui, sit amet porttitor nulla mollis nec. Cras volutpat nunc leo, nec finibus turpis ultrices ut. Aliquam sed lorem id velit posuere blandit non sed velit. Etiam consequat leo nibh.',
    emissionCategory: 'Power',
    subcategory: 'subcategory 1',
    uniqueIdentifier: 'ZBCGSE134',
    status: 'Scheduled',
    cost: 10000,
    kwhSavedYearly: 20.5,
    startDate: new Date('2023-01-01'),
    endDate: new Date('2023-04-01'),
    contactId: null,
    facilityId: null,
  },
  {
    id: '2',
    title: 'The Title for an action ticket targeting reduction of electricity consumption',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non quam nec neque volutpat faucibus. In feugiat imperdiet mattis. Aliquam eu ex quis sapien varius mollis in vel neque. Proin vehicula posuere elit, et lobortis elit accumsan non. Integer imperdiet aliquam dui, sit amet porttitor nulla mollis nec. Cras volutpat nunc leo, nec finibus turpis ultrices ut. Aliquam sed lorem id velit posuere blandit non sed velit. Etiam consequat leo nibh.',
    emissionCategory: 'Power',
    subcategory: 'subcategory 1',
    uniqueIdentifier: 'ZBCGSE134',
    status: 'Scheduled',
    cost: 4000,
    kwhSavedYearly: 12,
    startDate: new Date('2023-01-05'),
    endDate: new Date('2023-02-01'),
    contactId: null,
    facilityId: null,
  },
  {
    id: '3',
    title: 'The Title for an action ticket targeting reduction of gas consumption',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non quam nec neque volutpat faucibus. In feugiat imperdiet mattis. Aliquam eu ex quis sapien varius mollis in vel neque. Proin vehicula posuere elit, et lobortis elit accumsan non. Integer imperdiet aliquam dui, sit amet porttitor nulla mollis nec. Cras volutpat nunc leo, nec finibus turpis ultrices ut. Aliquam sed lorem id velit posuere blandit non sed velit. Etiam consequat leo nibh.',
    emissionCategory: 'Gas',
    subcategory: 'subcategory 1',
    uniqueIdentifier: 'ZBCGSE134',
    status: 'Scheduled',
    cost: 5600,
    kwhSavedYearly: 13.5,
    startDate: new Date('2023-02-01'),
    endDate: new Date('2023-04-27'),
    contactId: null,
    facilityId: null,
  },
  {
    id: '4',
    title: 'The Title for an action ticket targeting reduction of water consumption',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non quam nec neque volutpat faucibus. In feugiat imperdiet mattis. Aliquam eu ex quis sapien varius mollis in vel neque. Proin vehicula posuere elit, et lobortis elit accumsan non. Integer imperdiet aliquam dui, sit amet porttitor nulla mollis nec. Cras volutpat nunc leo, nec finibus turpis ultrices ut. Aliquam sed lorem id velit posuere blandit non sed velit. Etiam consequat leo nibh.',
    emissionCategory: 'Water',
    subcategory: 'subcategory 1',
    uniqueIdentifier: 'ZBCGSE134',
    status: 'Scheduled',
    cost: 3500,
    kwhSavedYearly: 16,
    startDate: new Date('2023-05-15'),
    endDate: new Date('2023-06-30'),
    contactId: null,
    facilityId: null,
  },
  {
    id: '5',
    title: 'The Title for an action ticket targeting reduction of gas consumption',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non quam nec neque volutpat faucibus. In feugiat imperdiet mattis. Aliquam eu ex quis sapien varius mollis in vel neque. Proin vehicula posuere elit, et lobortis elit accumsan non. Integer imperdiet aliquam dui, sit amet porttitor nulla mollis nec. Cras volutpat nunc leo, nec finibus turpis ultrices ut. Aliquam sed lorem id velit posuere blandit non sed velit. Etiam consequat leo nibh.',
    emissionCategory: 'Gas',
    subcategory: 'subcategory 1',
    uniqueIdentifier: 'ZBCGSE134',
    status: 'Scheduled',
    cost: 7500,
    kwhSavedYearly: 14.5,
    startDate: new Date('2023-07-01'),
    endDate: new Date('2023-09-01'),
    contactId: null,
    facilityId: null,
  },
  {
    id: '6',
    title: 'The Title for an action ticket targeting reduction of electricity consumption',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non quam nec neque volutpat faucibus. In feugiat imperdiet mattis. Aliquam eu ex quis sapien varius mollis in vel neque. Proin vehicula posuere elit, et lobortis elit accumsan non. Integer imperdiet aliquam dui, sit amet porttitor nulla mollis nec. Cras volutpat nunc leo, nec finibus turpis ultrices ut. Aliquam sed lorem id velit posuere blandit non sed velit. Etiam consequat leo nibh.',
    emissionCategory: 'Gas',
    subcategory: 'subcategory 1',
    uniqueIdentifier: 'ZBCGSE134',
    status: 'Scheduled',
    cost: 5000,
    kwhSavedYearly: 14,
    startDate: new Date('2023-11-01'),
    endDate: new Date('2024-01-15'),
    contactId: null,
    facilityId: null,
  },
]
