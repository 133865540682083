import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Field, Formik } from 'formik'
import i18n from 'i18n'
import styled from 'styled-components'
import * as Yup from 'yup'

import {
  ContactPersonForm,
  FormFooter,
  FormGroup,
  Modal,
  RequestRadio,
  SelectInput,
  StyledFileInput,
  StyledForm,
  SubmitButton,
  SwitchInput,
} from 'components'
import {
  getContacts,
  getFacility,
  getRequests,
  newRequest,
  putRequest,
  putRequestFile,
} from 'services'
import { Request, RequestType } from 'types'

type ContactOptionValue = {
  id: string
  isOwner?: boolean
}

interface ContactOption {
  label: string
  value: ContactOptionValue
}

const requestTypes = [
  {
    label: i18n.t('requests.requestTypes.malfunction'),
    value: RequestType.MALFUNTION,
  },
  {
    label: i18n.t('requests.requestTypes.request'),
    value: RequestType.REQUEST,
  },
  {
    label: i18n.t('requests.requestTypes.complaint'),
    value: RequestType.COMPLAINT,
  },
]

const initialValues = (request: Request): Request => ({
  type: request?.type || RequestType.NONE,
  isEntireFacility: request?.isEntireFacility || false,
  locationDescription: request?.locationDescription || '',
  details: request?.details || '',
  contactIds: request?.contacts?.flatMap((contact) => contact.id) || [],
})

const validationSchema = Yup.object().shape({
  type: Yup.mixed().oneOf(['None', 'Malfunction', 'Request', 'Complaint']).required(),
  isEntireFacility: Yup.boolean().required(),
  locationDescription: Yup.string().required(),
  details: Yup.string().required(),
  contactIds: Yup.array().of(Yup.string()).optional(),
})

export const RequestForm: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { facilityId, requestId } = useParams()
  const [contactModalState, setContactModalState] = useState<'open' | 'closed'>('closed')
  const { data: facility } = useQuery(['facility', facilityId], () => getFacility(facilityId))
  const { data: contacts } = useQuery(['contacts', facilityId], () => getContacts(facilityId))
  const { data: requests } = useQuery(['requests', facilityId], () => getRequests(facilityId))
  const request = requests?.find((request) => request.id === requestId)

  const [contactsOptions, setContactsOptions] = useState<ContactOption[]>([])

  const newRequestMutation = useMutation((values: Request) => newRequest(facilityId, values))

  const updateRequestMutation = useMutation((values: Request) =>
    putRequest(facilityId, requestId, values),
  )

  const requestMutation = request ? updateRequestMutation : newRequestMutation

  const handleSubmit = async (values: Request) => {
    requestMutation.mutate(values, {
      onSuccess: (data) => {
        if (values.files?.length) {
          values.files.forEach((file) => {
            putRequestFile(facilityId, data, file)
          })
        }

        queryClient.invalidateQueries({ queryKey: ['requests', facilityId] })
        navigate(`/fix/facilities/${facilityId}/requests/${data}`)
      },
    })
  }

  useEffect(() => {
    const options = contacts?.map((contact) => ({
      label: `${contact.firstName} ${contact.lastName}`,
      value: { id: contact.id },
    }))

    setContactsOptions(options)
  }, [contacts])

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues(request)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, isSubmitting, isValid }) => {
          return (
            <StyledForm>
              <h1>
                {facility?.name}: {request ? values.type : t('requests.new')}
              </h1>
              <FormGroup>
                <h3>1. {t('requests.requestType')}</h3>
                <RequestRadio
                  value={request?.type}
                  options={requestTypes}
                  handleChange={(value) => setFieldValue('type', value)}
                />
              </FormGroup>

              <FormGroup width='narrow'>
                <h3>2. {t('requests.exactLocation')}</h3>
                <SwitchInput
                  label={t('requests.entireFacility')}
                  handleChange={(e) => setFieldValue('isEntireFacility', e.target.checked)}
                />

                <Field
                  name='locationDescription'
                  as='textarea'
                  placeholder={t('requests.exactLocationPlaceHolder')}
                />
              </FormGroup>

              <FormGroup width='narrow'>
                <h3>3. {t('requests.requestDetails')}</h3>
                <Field name='details' as='textarea' placeholder={t('requests.cause')} />
                <StyledFileInput
                  accept='.pdf, .jpeg, .jpg, .png'
                  multiple={true}
                  name='files'
                  setFieldValue={setFieldValue}
                />
              </FormGroup>

              <FormGroup width='narrow'>
                <h3>4. {t('contacts.contactPerson')} </h3>
                <p>{t('requests.mailRequest')}</p>
                <SelectInput
                  name='contactIds'
                  options={contactsOptions}
                  placeholder={
                    contactsOptions?.length > 0
                      ? t('contacts.contactPersonSelect')
                      : t('contacts.contactPersonAdd')
                  }
                  selected={values.contactIds?.[0]}
                  flatten={true}
                />
                <InlineButton onClick={() => setContactModalState('open')}>
                  {t('contacts.addperson')}
                </InlineButton>
              </FormGroup>
              <FormFooter>
                <SubmitButton
                  completed={false}
                  completedText={t('requests.requestCreated')}
                  submitText={t('requests.submitRequest')}
                  loading={isSubmitting}
                  disabled={!isValid}
                />
              </FormFooter>
            </StyledForm>
          )
        }}
      </Formik>
      <Modal state={contactModalState}>
        <ContactPersonForm
          facilityId={facilityId}
          closeForm={() => setContactModalState('closed')}
        />
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  form {
    width: 100%;
  }

  .card {
    width: 375px;
  }
`
const InlineButton = styled.a`
  position: relative;
  margin: 20px 0 20px 0;
  color: ${({ theme }) => theme.colors.berryLight.hex};
  font-family: 'Radio Canada';
  font-weight: 500;
  font-stretch: condensed;
  text-align: left;
  width: max-content;
  cursor: pointer;
`
