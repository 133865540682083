import React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'

import { Layout } from 'components'
import {
  ClusterDetail,
  ClusterForm,
  DocumentForm,
  DocumentsOverview,
  FacilitiesOverview,
  FacilityCareDetail,
  FacilityDetail,
  FacilityForm,
  Home,
  Login,
  NewPolicy,
  PoliciesOverview,
  PolicyDetail,
  RequestDetail,
  RequestForm,
} from 'views'
import { User } from 'types'

const user: User = JSON.parse(localStorage.getItem('user'))

const Routes = () =>
  useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: user ? <Home /> : <Login />,
        },
      ],
    },
    {
      path: '/map',
      element: user ? <Layout tool='map' /> : <Login />,
      children: [
        { path: '/map/facilities', element: <FacilitiesOverview /> },
        { path: '/map/facilities/new', element: <FacilityForm /> },
        { path: '/map/facilities/:facilityId', element: <FacilityDetail /> },
        { path: '/map/facilities/:facilityId/edit', element: <FacilityForm /> },
        { path: '/map/facilities/:facilityId/clusters/new', element: <ClusterForm /> },
        { path: '/map/policies', element: <PoliciesOverview /> },
        { path: '/map/policies/:policyId', element: <PolicyDetail /> },
        { path: '/map/policies/new', element: <NewPolicy /> },
        {
          path: '/map/facilities/:facilityId/clusters/:clusterId',
          element: <ClusterDetail />,
        },
        { path: '/map/facilities/:facilityId/clusters/:clusterId/edit', element: <ClusterForm /> },
      ],
    },
    {
      path: '/fix',
      element: user ? <Layout tool='fix' /> : <Login />,
      children: [
        { path: '/fix/facilities', element: <FacilitiesOverview /> },
        { path: '/fix/facilities/:facilityId', element: <FacilityDetail /> },
        { path: '/fix/facilities/:facilityId/requests/new', element: <RequestForm /> },
        { path: '/fix/facilities/:facilityId/requests/:requestId', element: <RequestDetail /> },
        { path: '/fix/facilities/:facilityId/requests/:requestId/edit', element: <RequestForm /> },
      ],
    },
    {
      path: '/docs',
      element: user ? <Layout tool='docs' /> : <Login />,
      children: [
        { path: '/docs/facilities', element: <FacilitiesOverview /> },
        { path: '/docs/facilities/:facilityId', element: <DocumentsOverview /> },
        { path: '/docs/facilities/:facilityId/documents/new', element: <DocumentForm /> },
        {
          path: '/docs/facilities/:facilityId/documents/:documentId/edit',
          element: <DocumentForm />,
        },
      ],
    },
    {
      path: '/costs',
      element: user ? <Layout tool='costs' /> : <Login />,
      children: [
        { path: '/costs/facilities', element: <FacilitiesOverview /> },
        { path: '/costs/facilities/:facilityId', element: <FacilityDetail /> },
      ],
    },
    {
      path: '/care',
      element: <Layout tool='care' />,
      children: [
        { path: '/care/facilities', element: <FacilitiesOverview /> },
        { path: '/care/facilities/:facilityId', element: <FacilityCareDetail /> },
      ],
    },
  ])

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}
