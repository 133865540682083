import { initReactI18next } from 'react-i18next'

import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import EN from './translations/en/EN_translations.json'
import NL from './translations/nl/NL_translations.json'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'nl',
    resources: {
      nl: {
        translation: NL,
      },
      en: {
        translation: EN,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  })

i18next.languages = ['nl', 'en']

export default i18next
