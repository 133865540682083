import React from 'react'

import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

export const Toolbar: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
`
