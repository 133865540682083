import React from 'react'

import styled from 'styled-components'

interface Props {
  block?: boolean
  completed?: boolean
  completedText?: string
  submitText: string
  disabled?: boolean
  loading?: boolean
  type?: string
  submit?: boolean
  onClick?: () => void
}

export const SubmitButton: React.FC<Props> = ({
  block = false,
  completed,
  completedText,
  submitText,
  disabled,
  loading,
  type = 'primary',
  submit = true,
  onClick,
}) => {
  return (
    <Button
      type={submit ? 'submit' : 'button'}
      disabled={loading || completed || disabled}
      className={`submitButton ${type} ${block ? 'block' : ''}`}
      onClick={onClick}
    >
      {completed ? completedText : submitText}
    </Button>
  )
}

const Button = styled.button`
  padding: 12px 12px 14px;
  border-radius: 4px;
  text-align: center;
  transition: background-color 200ms ease, color 200ms ease;

  &.block {
    width: 100%;
  }

  &.primary {
    background-color: ${({ theme }) => theme.colors.body.primary};
    color: ${({ theme }) => theme.colors.button.textActive};

    &:hover {
      background-color: ${({ theme }) => theme.colors.body.primaryLight};
    }
  }

  &.secondary {
    background-color: ${({ theme }) => theme.colors.button.background};
    color: ${({ theme }) => theme.colors.button.text};

    &:hover {
      background-color: ${({ theme }) => theme.colors.button.backgroundHover};
      color: ${({ theme }) => theme.colors.button.textActive};
    }
  }

  &.login {
    background-color: ${({ theme }) => theme.colors.loginButton.background};
    color: ${({ theme }) => theme.colors.loginButton.text};
    &:hover {
      background-color: ${({ theme }) => theme.colors.loginButton.backgroundHover};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.body.primaryLight};
    cursor: pointer;
  }

  &:disabled,
  &.disabled {
    opacity: 0.4;

    &:hover {
      background-color: ${({ theme }) => theme.colors.button.background};
      color: ${({ theme }) => theme.colors.button.text};
      cursor: not-allowed;
    }
  }
`
