import axios from 'axios'
import formatcoords from 'formatcoords'

import { Address } from 'types'

interface Response {
  postcode: string
  number: number
  suffix?: string
  street: string
  city: string
  municipality: string
  province: string
  geo: {
    lat: number
    lon: number
  }
}

const http = axios.create({
  baseURL: 'https://postcode.tech/api/v1/postcode/full',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer 56772f79-07be-45ea-93da-bf7d0d972d04',
  },
})

const mapAddress = (address: Response): Address => {
  const { postcode, number, suffix, street, city, municipality, province } = address
  const { lat, lon } = address.geo
  const coords = formatcoords(`${lat}, ${lon}`)
    .format('DD MM ss X', {
      latLonSeparator: ',',
    })
    .split(',')

  const latFormatted = coords[0]
  const lonFormatted = coords[1]

  return {
    postCode: postcode,
    buildingNumber: number,
    extension: suffix || '',
    street,
    city,
    municipality,
    province,
    latitude: lat,
    longitude: lon,
    latFormatted,
    lonFormatted,
  }
}

export const getAddress = async (
  postcode: string,
  number: number,
  suffix?: string,
): Promise<Address> => {
  try {
    const address = await http.get<Response>(
      `/?postcode=${postcode}&number=${number}${suffix ? `&suffix=${suffix}` : ''}`,
    )

    return mapAddress(address.data)
  } catch (e) {
    console.debug(e)

    return null
  }
}
