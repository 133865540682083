import React, { ChangeEvent, useEffect, useState } from 'react'

import styled from 'styled-components'

import { AnimatedInput, SwitchInput } from 'components'

interface Props {
  label: string
  value: string
  handleChange?: (value: FormattedValue) => void
}

interface FormattedValue {
  day: string
  from?: string
  to?: string
  isOpen: boolean
}

export const OpenHoursInput: React.FC<Props> = ({ label, value, handleChange }) => {
  const [open, setOpen] = useState(false)
  const [formattedValue, setFormattedValue] = useState<FormattedValue>({
    day: value,
    isOpen: false,
  })

  const formatValues = (data: ChangeEvent) => {
    const target = data.target as HTMLInputElement
    const { name, value } = target

    setFormattedValue((state) => ({
      ...state,
      [name]: value,
    }))
  }

  useEffect(() => {
    setFormattedValue((state) => ({
      ...state,
      from: open ? state.from : '',
      to: open ? state.to : '',
      isOpen: open,
    }))
  }, [open])

  useEffect(() => {
    handleChange(formattedValue)
  }, [formattedValue])

  return (
    <OpenHoursGroup className={open ? 'open' : 'close'}>
      <label>
        <span>{label}</span>
        {open && <AnimatedInput name='from' value='From' handleChange={formatValues} />}
      </label>

      <label>
        <span>{open ? 'Open' : 'Closed'}</span>
        <SwitchInput handleChange={(e) => setOpen(e.target.checked)} />
        {open && <AnimatedInput name='to' value='To' handleChange={formatValues} />}
      </label>
    </OpenHoursGroup>
  )
}

export const OpenHoursGroup = styled.div`
  display: flex;
  position: relative;
  margin-top: 15px;

  > label {
    display: block;
    position: relative;
    width: 160px;
    padding-top: 28px;

    &:first-child {
      margin-right: 55px;

      &:not(.close) &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50px;
        right: -35px;
        width: 15px;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.body.text};
      }
    }

    > span {
      position: absolute;
      top: 0;
      left: 0;
    }

    .styledSwitch {
      position: absolute;
      top: -5px;
      right: 0;
    }
  }
`
