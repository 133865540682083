import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'
import styled from 'styled-components'

import { Button, ContactCard, ContactPersonForm } from 'components'
import { getContacts } from 'services'

interface Props {
  facilityId: string
}

export const ContactsTab: React.FC<Props> = ({ facilityId }) => {
  const { t } = useTranslation()
  const { data: contacts } = useQuery(['contacts', facilityId], () => getContacts(facilityId))
  const [showNewContactForm, setShowNewContactForm] = useState(false)

  return (
    <>
      <Header>
        <h3>{t('contacts.contactPersons')}</h3>
        <Button onClick={() => setShowNewContactForm(true)} icon='add' />
      </Header>

      {!contacts?.length ? (
        <p>
          {t('contacts.noContacts')}{' '}
          <a href='#' onClick={() => setShowNewContactForm(true)}>
            {t('common.create')}
          </a>
        </p>
      ) : (
        <ContactsContainer>
          {contacts.map((contact, i) => (
            <ContactCard key={contact.id} contact={contact} number={i} total={contacts.length} />
          ))}
        </ContactsContainer>
      )}

      {showNewContactForm && (
        <FormContainer>
          <ContactPersonForm
            facilityId={facilityId}
            closeForm={() => setShowNewContactForm(false)}
          />
        </FormContainer>
      )}
    </>
  )
}

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const ContactsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
`

const FormContainer = styled.div`
  width: 375px;
`
