const recursiveNullifyEmptyStrings = <Type>(obj: Type): Type => {
  for (const key of Object.keys(obj)) {
    const prop = key as keyof Type

    if (obj[prop] === '') {
      delete obj[prop]
    } else if (typeof obj[prop] === 'object') {
      obj[prop] = recursiveNullifyEmptyStrings(obj[prop])

      if (Object.keys(obj[prop]).length === 0) delete obj[prop]
    }
  }

  return obj
}

export const nullifyEmptyStrings = <Type>(obj: Type): Type => {
  return recursiveNullifyEmptyStrings(obj)
}
