import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'
import styled from 'styled-components'

import { useAppData } from 'context'
import { getPolicyImg } from 'services'
import { cardContainer, cardDetailItem, cardDetailList, cardImageWrapper } from 'styles'
import { Policy } from 'types'

interface Props {
  className?: string
  policy: Policy
  number?: number
  total?: number
}

interface DetailItem {
  className?: string
  label: string
  value: string | number
}

export const PolicyCard: React.FC<Props> = ({ className, policy, number, total }) => {
  const { view } = useAppData()
  const navigate = useNavigate()

  const { data: image } = useQuery(['PolicyImg', policy.id], () => getPolicyImg(policy.id), {
    enabled: !!policy.image,
    staleTime: 1000 * 60 * 60,
  })

  const detailItems: DetailItem[] = policy
    ? [
        { label: 'name', value: policy.name },
        { label: 'countClusters', value: (Math.random() * (10 - 5)).toFixed(0) },
        { label: 'averageScore', value: (Math.random() * (10 - 6) + 6).toFixed(1) },
      ]
    : [{ label: 'status', value: policy.priority, className: 'highlight' }]

  return (
    <CardContainer
      className={`${className} ${view}`}
      onClick={() => navigate(`/map/policies/${policy.id}`)}
      number={number}
      total={total}
    >
      {!!policy.image && (
        <ImageWrapper>
          {image ? <img src={image} alt={policy.name} /> : <Skeleton width={90} height={90} />}
        </ImageWrapper>
      )}
      <Content>
        <h3>{policy.name}</h3>
        <DetailList>
          {detailItems &&
            detailItems
              .filter((detail) => detail.value !== '' && detail.value !== 'None')
              .map(({ className, value }, i) => (
                <DetailItem key={i} className={className}>
                  {value}
                </DetailItem>
              ))}
        </DetailList>
      </Content>
    </CardContainer>
  )
}

interface CardProps {
  number?: number
  total?: number
}

const CardContainer = styled.div<CardProps>`
  ${cardContainer}

  background-color: ${({ theme }) => theme.colors.card.backgroundAlt};
`

const ImageWrapper = styled.div`
  ${cardImageWrapper}
  border-radius: 50%;
`

const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
`

const DetailList = styled.ul`
  ${cardDetailList}
`

const DetailItem = styled.li`
  ${cardDetailItem}
`
