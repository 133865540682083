import { Document } from 'types'

import http from './http-common'

export const getDocuments = async (facilityId: string): Promise<Document[]> =>
  http.get<Document[]>(`/facility/${facilityId}/document`).then((response) => response.data)

export const downloadDocument = async (facilityId: string, documentId: string): Promise<string> =>
  http
    .get(`/facility/${facilityId}/document/${documentId}/download`, { responseType: 'blob' })
    .then((response) => URL.createObjectURL(response.data))

export const newDocument = async (facilityId: string, doc: Document) => {
  try {
    const id = await http.post(
      `/facility/${facilityId}/document`,
      {
        title: doc.title,
        status: doc.status,
        type: doc.type,
        file: doc.file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    return id
  } catch (e) {
    console.debug(e)
  }
}

export const deleteDocument = async (facilityId: string, documentId: string) => {
  try {
    const id = await http.delete(`/facility/${facilityId}/document/${documentId}`)

    return id
  } catch (e) {
    console.debug(e)
  }
}
