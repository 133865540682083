import React from 'react'

import { Info } from '@mui/icons-material'
import { Switch, Tooltip } from '@mui/material'
import styled from 'styled-components'

interface Props {
  checked?: boolean
  label?: string
  tooltip?: string
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const SwitchInput: React.FC<Props> = ({ checked, label, tooltip, handleChange }) => {
  return (
    <SwitchWrapper className='styledSwitch'>
      {label && (
        <label>
          {label}
          {tooltip && (
            <Tooltip title={tooltip} placement='top'>
              <Info fontSize='inherit' style={{ marginLeft: '15px' }} />
            </Tooltip>
          )}
        </label>
      )}
      <Switch checked={checked} onChange={handleChange} />
    </SwitchWrapper>
  )
}

const SwitchWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .MuiSwitch-root {
    width: 55px;
    padding-right: 0;
    background-color: transparent;

    .MuiSwitch-thumb {
      width: 15px;
      height: 15px;
      border-radius: 3px;
      background-color: ${({ theme }) => theme.colors.inputs.switch.fill};
      border: 2px solid ${({ theme }) => theme.colors.inputs.switch.border};
    }

    .MuiSwitch-track {
      height: 4px;
      margin-top: 4px;
      border-radius: 3px;
      background-color: ${({ theme }) => theme.colors.inputs.switch.border};
      opacity: 1;
    }
  }

  .MuiSwitch-switchBase {
    &.Mui-checked {
      transform: translateX(27px);

      .MuiSwitch-thumb {
        background-color: ${({ theme }) => theme.colors.inputs.switch.active};
      }

      & + .MuiSwitch-track {
        background-color: ${({ theme }) => theme.colors.inputs.switch.active};
        opacity: 1;
      }
    }
  }
`
