import React, { useState } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import styled from 'styled-components'

import { ContactPersonForm, DropdownItem, DropdownMenu, Modal } from 'components'
import { useOutsideClick } from 'hooks'
import { deleteContact } from 'services'
import { cardBody, cardContainer, cardHeader } from 'styles'
import { Contact } from 'types'

interface Props {
  contact: Contact
  number: number
  total: number
}

export const ContactCard: React.FC<Props> = ({ contact, number, total }) => {
  const queryClient = useQueryClient()
  const [contactModalState, setContactModalState] = useState<'open' | 'closed'>('closed')
  const [showDropdown, setShowDropdown] = useState(false)
  const ref = useOutsideClick(() => setShowDropdown(false))

  const removeContact = useMutation((id: string) => deleteContact(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['contacts', contact.facilityId])
    },
  })

  return (
    <>
      <CardContainer number={number} total={total}>
        <CardHeader>
          <h3>
            {contact.firstName} {contact.lastName}
          </h3>
          <DropdownMenu innerRef={ref} show={showDropdown} setShow={setShowDropdown}>
            <DropdownItem name='edit' action={() => setContactModalState('open')} />
            <DropdownItem
              name='delete'
              style='danger'
              action={() => removeContact.mutate(contact.id)}
            />
          </DropdownMenu>
        </CardHeader>
        <CardBody>
          <p>{contact.title}</p>
          <a href={`mailto:${contact.email}`}>{contact.email}</a>
          <p>{contact.phone}</p>
        </CardBody>
      </CardContainer>
      <Modal state={contactModalState}>
        <ContactPersonForm
          contact={contact}
          facilityId={contact.facilityId}
          closeForm={() => setContactModalState('closed')}
        />
      </Modal>
    </>
  )
}

const CardContainer = styled.div`
  ${cardContainer}

  flex-flow: row wrap;
  padding: 12px 20px;

  &:hover {
    cursor: default;
  }
`

const CardHeader = styled.div`
  ${cardHeader}
`

const CardBody = styled.div`
  ${cardBody}
`
