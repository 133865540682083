import React from 'react'
import Skeleton from 'react-loading-skeleton'

import styled from 'styled-components'

interface Props {
  amount: number
}

export const CardSkeleton: React.FC<Props> = ({ amount }) => {
  return (
    <>
      {Array(amount)
        .fill(0)
        .map((_, i) => (
          <SkeletonContainer key={i}>
            <SkeletonImg>
              <Skeleton width={90} height={90} />
            </SkeletonImg>
            <SkeletonContent>
              <Skeleton width={225} height={15} count={3} />
            </SkeletonContent>
          </SkeletonContainer>
        ))}
    </>
  )
}

const SkeletonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.card.background};
  border-radius: 8px;
  box-shadow: none;
  align-items: center;
  transform: scale(1) translateY(0);
  transition: box-shadow 300ms ease, transform 300ms ease;
`

const SkeletonImg = styled.div`
  position: relative;
  width: 90px;
  height: 90px;
  margin-right: 12px;
`

const SkeletonContent = styled.div`
  display: flex;
  height: 100%;
  flex-flow: column wrap;
  justify-content: start;
`
