import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import { cardBody, cardContainer, cardImageWrapper } from 'styles'

type Size = 'small' | 'large'

export interface Crumb {
  detailList?: (string | number)[]
  title: string
  link: string
  image?: string
  imageShape?: 'circle' | 'square'
}

interface Props {
  crumbs: Crumb[]
  size: Size
  className?: string
}

export const BreadcrumbNav: React.FC<Props> = ({ className, crumbs, size }) => {
  const navigate = useNavigate()

  return (
    <Container className={`${size} ${className}`}>
      {crumbs &&
        crumbs.map(({ detailList, title, link, image, imageShape }: Crumb, i) => (
          <CardContainer key={i} onClick={() => navigate(link)}>
            {image && (
              <ImageWrapper className={imageShape}>
                <img src={image} />
              </ImageWrapper>
            )}
            <CardBody>
              <Title>{title}</Title>
              {detailList && (
                <DetailList>
                  {detailList.map((detail, i) => (
                    <DetailItem key={i}>{detail}</DetailItem>
                  ))}
                </DetailList>
              )}
            </CardBody>
          </CardContainer>
        ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 18px 0;
  flex-flow: row wrap;
  gap: 18px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -50vw;
    width: 100vw;
    height: 1px;
    margin-left: 50%;
    background-color: ${({ theme }) => theme.colors.body.backgroundAlt};
  }
`

const CardContainer = styled.div`
  ${cardContainer}

  background-color: ${({ theme }) => theme.colors.card.backgroundAlt};

  padding: 10px;
  flex: 0 0 200px;
  border-radius: 4px;

  ${Container}.large & {
    flex: 0 0 375px;
    padding: 20px;
    border-radius: 8px;
  }
`

const ImageWrapper = styled.div`
  ${cardImageWrapper}

  width: 30px;
  height: 30px;
  margin-right: 10px;
  text-align: center;

  img {
    width: inherit;
    height: inherit;
  }

  &.circle {
    border-radius: 50%;
  }

  ${Container}.large & {
    width: 90px;
    height: 90px;
    margin-right: 20px;
  }
`

const CardBody = styled.div`
  ${cardBody}
`

const Title = styled.span`
  color: ${({ theme }) => theme.colors.body.text};
  font-family: 'Radio Canada';
  font-size: 18px;
  font-weight: 500;
  font-stretch: condensed;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${Container}.large & {
    font-size: 24px;
  }
`

const DetailList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  margin: 8px 0 0;
  padding: 0;
  list-style-type: none;
`

const DetailItem = styled.li`
  &:not(:last-child) {
    position: relative;
    margin-right: 25px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 2.5px);
      right: -15px;
      width: 5px;
      height: 5px;
      background-color: ${({ theme }) => theme.colors.body.text};
      border-radius: 50%;
    }
  }
`
